<template>
  <div class="step-options">
    <h2>Step 2: Options</h2>
    <div class="form-container">
      <!-- Option Form -->
      <div class="option-form">
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="dealName">Gift Card Name</label>
            <input type="text" id="dealName" v-model="currentOption.dealName" maxlength="120" @input="validateForm" />
            <small>{{ 120 - currentOption.dealName.length }} Characters remaining</small>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="releaseAmount">Release Amount</label>
              <input type="number" id="releaseAmount" v-model.number="currentOption.releaseAmount" />
            </div>
            <div class="form-group">
              <label for="releaseFrequency">Release Frequency</label>
              <select id="releaseFrequency" v-model="currentOption.releaseFrequency">
                <option value="" disabled>Select Frequency</option>
                <option value="monthly">Monthly</option>
                <option value="weekly">Weekly</option>
                <option value="exactDate">Exact Date</option>
                <option value="none">None</option> <!-- Added 'none' -->
              </select>
            </div>
            <div class="form-group" v-if="currentOption.releaseFrequency === 'exactDate'">
              <label for="exactDate">Exact Date</label>
              <input type="date" id="exactDate" v-model="currentOption.exactDate" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="currentPrice">Card Amount ($)</label>
              <input type="number" id="currentPrice" v-model="currentOption.currentPrice" min="1" max="999" step="0.01" />
              <small>Card Amount should be between $1 and $999</small>
            </div>
            <div class="form-group">
              <label for="numberInStock">Number in stock</label>
              <input type="number" id="numberInStock" v-model.number="currentOption.numberInStock" />
            </div>
            <div class="form-group">
              <label for="maxPerPurchase">Max Purchase Amount (per customer)</label>
              <input type="number" id="maxPerPurchase" v-model.number="currentOption.maxPerPurchase" />
            </div>
          </div>
          <div class="form-group">
            <label for="giftCardCode">Gift Card Code</label>
            <div class="coupon-code-container">
              <input type="text" id="giftCardCode" :value="formattedGiftCardCode" readonly />
              <button type="button" @click="generateCodes">Generate Codes</button>
              <button type="button" v-if="codesGenerated" @click="toggleShowGiftCards">{{ showGiftCards ? 'Hide Gift Cards' : 'Show Gift Cards' }}</button>
            </div>
            <div v-if="showGiftCards">
              <h3>Gift Card Codes</h3>
              <table class="gift-cards-table">
                <thead>
                  <tr>
                    <th>Gift Card Code</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(code, index) in currentGiftCardCodes" :key="index">
                    <td>{{ formatCode(code) }}</td>
                    <td>
                      <button @click="editCode(index)">Edit</button>
                      <button @click="deleteCode(index)">Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- New input field for discount -->
          <div class="form-group">
            <label for="discount">Discount (%)</label>
            <input
              type="number"
              id="discount"
              v-model.number="currentOption.discount"
              min="0"
              max="100"
              step="1"
            />
            <small>Discount should be between 0% and 100%</small>
          </div>
          <!-- Input field for business cut -->
          <div class="form-group">
            <label for="businessCut">Business Cut (%)</label>
            <input
              type="number"
              id="businessCut"
              v-model.number="currentOption.businessCut"
              min="0"
              max="100"
              step="1"
            />
            <small>Business cut should be between 0% and 100%</small>
          </div>
          <!-- Price Calculations -->
          <div class="price-calculations">
            <div class="calculation">
              <div class="label">Customer Pays</div>
              <div class="value">${{ calculateCustomerPays(currentOption) }}</div>
            </div>
            <div class="calculation">
              <div class="label">Business Gets</div>
              <div class="value">${{ calculateYouGet(currentOption) }}</div>
            </div>
            <div class="calculation">
              <div class="label">Trio Tech Gets</div>
              <div class="value">${{ calculateShopSharkGets(currentOption) }}</div>
            </div>
          </div>
          <div class="button-group">
            <button type="submit">{{ editingIndex === null ? 'Add Option' : 'Update Option' }}</button>
            <button type="button" @click="clearForm">Clear Form</button>
          </div>
        </form>
      </div>

      <!-- Created Options List -->
      <div class="options-list">
        <h3>Created Options</h3>
        <ul>
          <li v-for="(option, index) in savedOptions" :key="index">
            <div class="option-summary">
              <strong>{{ option.dealName }}</strong> - ${{ option.currentPrice }}
            </div>
            <div class="option-actions">
              <button @click="editOption(index)">Edit</button>
              <button @click="confirmDeleteOption(index)">Delete</button>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Delete Confirmation Modal -->
    <div v-if="showDeleteConfirm" class="delete-confirmation">
      <p>Are you sure you want to delete this option?</p>
      <button @click="deleteOption">Yes</button>
      <button @click="cancelDelete">No</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';

export default {
  name: 'StepOptions',
  setup() {
    const currentOption = ref({
      dealName: '',
      currentPrice: 0,
      numberInStock: 0,
      discount: 0,
      giftCardCodes: [],
      maxPerPurchase: 0,
      releaseAmount: 0,
      releaseFrequency: 'none',
      exactDate: '',
      businessCut: 50,
    });

    const initialNumberInStock = ref(0); // Track initial number in stock
    const savedOptions = ref([]);
    const editingIndex = ref(null);
    const showDeleteConfirm = ref(false);
    const deleteOptionIndex = ref(null); // Store index of option to delete
    const codesGenerated = ref(false);
    const showGiftCards = ref(false);
    const generatedCodes = ref([]); // Track all generated codes across options
    const currentGiftCardCodes = ref([]); // Track gift card codes to display for current option
    const formattedGiftCardCode = ref(''); // Track formatted gift card code for input
    const isFormValid = ref(false);

    // Function to validate the form
    const validateForm = () => {
      const option = currentOption.value;
      isFormValid.value =
        option.dealName &&
        option.currentPrice > 0 &&
        option.numberInStock > 0 &&
        option.discount >= 0 &&
        option.maxPerPurchase > 0 &&
        option.businessCut >= 0;
    };

    // Populate the created options and generatedCodes from localStorage
    const populateCreatedOptions = () => {
      const storedOptions = JSON.parse(localStorage.getItem('option')) || {};
      const storedCodes = JSON.parse(localStorage.getItem('generatedCodes')) || [];

      // Populate saved options
      if (storedOptions && storedOptions.dealName && storedOptions.dealName.length > 0) {
        for (let i = 0; i < storedOptions.dealName.length; i++) {
          savedOptions.value.push({
            dealName: storedOptions.dealName[i] || '',
            currentPrice: storedOptions.currentPrice[i] || 0,
            numberInStock: storedOptions.numberInStock[i] || 0,
            discount: storedOptions.discount[i] || 0,
            maxPerPurchase: storedOptions.maxPerPurchase[i] || 0,
            releaseAmount: storedOptions.releaseAmount[i] || 0,
            releaseFrequency: storedOptions.releaseFrequency[i] || 'none',
            exactDate: storedOptions.exactDate ? storedOptions.exactDate[i] : '',
            businessCut: storedOptions.businessCut[i] || 50,
          });
        }
      }

      // Populate generated codes
      generatedCodes.value = storedCodes;
      console.log('Populated savedOptions:', savedOptions.value);
      console.log('Populated generatedCodes:', generatedCodes.value);
    };

    // Handle editing an option
    const editOption = (index) => {
      currentOption.value = { ...savedOptions.value[index] };
      initialNumberInStock.value = currentOption.value.numberInStock;
      
      // Ensure generatedCodes[index] exists and is an array
      currentGiftCardCodes.value = Array.isArray(generatedCodes.value[index]) 
        ? [...generatedCodes.value[index]] 
        : [];
      
      // Only format code if there is one
      formattedGiftCardCode.value = currentGiftCardCodes.value.length > 0 
        ? formatCode(currentGiftCardCodes.value[0])
        : '';
        
      codesGenerated.value = currentGiftCardCodes.value.length > 0;
      showGiftCards.value = false;
      editingIndex.value = index;
    };

    // Handle form submission for adding/updating an option
    const handleSubmit = () => {
      if (editingIndex.value !== null) {
        savedOptions.value[editingIndex.value] = { ...currentOption.value };
        generatedCodes.value[editingIndex.value] = [...currentGiftCardCodes.value];
        editingIndex.value = null;
      } else {
        savedOptions.value.push({ ...currentOption.value });
        generatedCodes.value.push([...currentGiftCardCodes.value]);
      }
      saveOptions();
      clearForm();
    };

    // Save options and generatedCodes to localStorage
    const saveOptions = () => {
      const storedOption = {
        dealName: savedOptions.value.map((opt) => opt.dealName),
        currentPrice: savedOptions.value.map((opt) => opt.currentPrice),
        numberInStock: savedOptions.value.map((opt) => opt.numberInStock),
        discount: savedOptions.value.map((opt) => opt.discount),
        maxPerPurchase: savedOptions.value.map((opt) => opt.maxPerPurchase),
        releaseAmount: savedOptions.value.map((opt) => opt.releaseAmount),
        releaseFrequency: savedOptions.value.map((opt) => opt.releaseFrequency),
        exactDate: savedOptions.value.map((opt) => opt.exactDate),
        businessCut: savedOptions.value.map((opt) => opt.businessCut),
      };
      localStorage.setItem('option', JSON.stringify(storedOption));
      localStorage.setItem('generatedCodes', JSON.stringify(generatedCodes.value));
    };

    // Clear the form
    const clearForm = () => {
      currentOption.value = {
        dealName: '',
        currentPrice: 0,
        numberInStock: 0,
        discount: 0,
        giftCardCodes: [],
        maxPerPurchase: 0,
        releaseAmount: 0,
        releaseFrequency: 'none',
        exactDate: '',
        businessCut: 50,
      };
      currentGiftCardCodes.value = [];
      formattedGiftCardCode.value = '';
      codesGenerated.value = false;
      showGiftCards.value = false;
      validateForm();
    };

    // Generate gift card codes if the number in stock has increased
    const generateCodes = () => {
    // Check if the number in stock is greater than the current number of codes
    if (currentGiftCardCodes.value.length >= currentOption.value.numberInStock) {
        alert('Increase the "Number in stock" value to generate more codes.');
        return;
    }

    // Define characters used in the code generation
    const chars = '0123456789';
    
    // Generate the base code as a single continuous number (16 digits)

    // Calculate how many new codes are needed
    const newCodesCount = currentOption.value.numberInStock - currentGiftCardCodes.value.length;

    // Generate the new codes based on the number needed
    for (let i = 0; i < newCodesCount; i++) {
        let newCode = '';
        for (let j = 0; j < 16; j++) {
            newCode += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        currentGiftCardCodes.value.push(newCode);
    }

    // Store the codes in local storage without any hyphens
    localStorage.setItem('giftCardCodes', JSON.stringify(currentGiftCardCodes.value));

    // Format the first code for display purposes only (does not affect storage)
    formattedGiftCardCode.value = formatCode(currentGiftCardCodes.value[0]);
    codesGenerated.value = true;
    showGiftCards.value = true;
};

    // Toggle showing gift cards
    const toggleShowGiftCards = () => {
      showGiftCards.value = !showGiftCards.value;
    };

    // Format gift card code for display (splits into 4-character segments)
    const formatCode = (code) => {
        if (!code) return ''; // Return empty string if code is null or undefined
        
        // Remove any existing hyphens and non-numeric characters
        const cleanCode = code.toString().replace(/[^0-9]/g, '');
        
        // If the cleaned code is empty, return empty string
        if (!cleanCode) return '';
        
        // Split into groups of 4 and join with hyphens
        const groups = cleanCode.match(/.{1,4}/g);
        return groups ? groups.join('-') : cleanCode;
    };

    // Edit a gift card code
    const editCode = (index) => {
      // Ensure the code exists before trying to format it
      const currentCode = currentGiftCardCodes.value[index];
      const formattedCurrentCode = currentCode ? formatCode(currentCode) : '';
      
      const newCode = prompt('Edit Gift Card Code:', formattedCurrentCode);
      if (newCode) {
        currentGiftCardCodes.value[index] = newCode.replace(/-/g, '');
      }
    };

    // Delete a gift card code
    const deleteCode = (index) => {
      currentGiftCardCodes.value.splice(index, 1);
      if (editingIndex.value !== null) {
        generatedCodes.value[editingIndex.value] = [...currentGiftCardCodes.value];
        saveOptions(); // Ensure the changes are saved to localStorage
      }
    };

    // Confirm delete option
    const confirmDeleteOption = (index) => {
      showDeleteConfirm.value = true;
      deleteOptionIndex.value = index;
    };

    // Delete an option
    const deleteOption = () => {
      savedOptions.value.splice(deleteOptionIndex.value, 1);
      generatedCodes.value.splice(deleteOptionIndex.value, 1); // Remove associated gift card codes
      saveOptions();
      showDeleteConfirm.value = false;
    };

    // Cancel delete option
    const cancelDelete = () => {
      showDeleteConfirm.value = false;
    };

    // Calculate Customer Pays
    const calculateCustomerPays = (option) => {
      return (option.currentPrice * (1 - option.discount / 100)).toFixed(2);
    };

    // Calculate what the business gets
    const calculateYouGet = (option) => {
      return (option.currentPrice * (1 - option.discount / 100) * (option.businessCut / 100)).toFixed(2);
    };

    // Calculate what ShopShark gets
    const calculateShopSharkGets = (option) => {
      return (option.currentPrice * (1 - option.discount / 100) * ((100 - option.businessCut) / 100)).toFixed(2);
    };

    // Populate options on component mount
    onMounted(() => {
      populateCreatedOptions();
      validateForm();
    });

    watch(currentOption, validateForm, { deep: true });

    return {
      currentOption,
      savedOptions,
      editingIndex,
      deleteOptionIndex,
      generatedCodes,
      currentGiftCardCodes,
      formattedGiftCardCode,
      codesGenerated,
      showGiftCards,
      isFormValid,
      showDeleteConfirm,
      handleSubmit,
      clearForm,
      editOption,
      generateCodes,
      toggleShowGiftCards,
      formatCode,
      editCode,
      deleteCode,
      confirmDeleteOption,
      deleteOption,
      cancelDelete,
      calculateCustomerPays,
      calculateYouGet,
      calculateShopSharkGets,
    };
  },
};
</script>



<style scoped>
.step-options {
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}

.form-container {
  display: flex;
  justify-content: space-between;
}

.option-form {
  width: 60%;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.options-list {
  width: 35%;
  padding-left: 20px;
  border-left: 1px solid #ccc;
}

.form-group {
  margin-bottom: 15px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.form-row .form-group {
  flex: 1 1 calc(50% - 20px);
  margin: 0 10px 15px;
  min-width: 200px;
}

.coupon-code-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

small {
  display: block;
  margin-top: 5px;
  color: #888;
}

input,
select,
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

button {
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #6E9F49;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.price-calculations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.calculation {
  text-align: center;
  flex: 1 1 30%;
  margin-bottom: 15px;
}

.calculation .label {
  font-size: 1em;
  font-weight: bold;
}

.calculation .value {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.gift-cards-table-container {
  overflow-x: auto;
}

.gift-cards-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.gift-cards-table th,
.gift-cards-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.gift-cards-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.gift-cards-table td {
  text-align: center;
}

.gift-cards-table button {
  margin-left: 10px;
}

.options-list ul {
  list-style-type: none;
  padding: 0;
}

.options-list li {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 10px;
}

.option-summary {
  margin-bottom: 5px;
}

.option-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

@media (max-width: 1024px) {
  .form-container {
    flex-direction: column;
  }

  .option-form,
  .options-list {
    width: 100%;
  }

  .options-list {
    margin-top: 20px;
    padding-left: 0;
    border-left: none;
    border-top: 1px solid #ccc;
    padding-top: 20px;
  }
}

@media (max-width: 768px) {
  .form-row .form-group {
    flex: 1 1 100%;
  }

  .coupon-code-container {
    flex-direction: column;
  }

  .coupon-code-container button {
    width: 100%;
  }

  .price-calculations {
    flex-direction: column;
  }

  .calculation {
    flex: 1 1 100%;
  }
}

@media (max-width: 480px) {
  .step-options {
    padding: 0 10px;
  }

  .form-group {
    margin-bottom: 10px;
  }

  input,
  select,
  textarea {
    padding: 8px;
  }

  .calculation .value {
    font-size: 1.2em;
  }

  .button-group {
    flex-direction: column;
  }

  .button-group button {
    width: 100%;
  }
}
</style>
