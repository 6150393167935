<template>
  <div class="step-preview">
    <MetaManager
      :title="metaTitle"
      :description="metaDescription"
    />
    <!-- Deal Title and Business Info -->
    <div class="deal-header">
      <h2 class="deal-title">{{ giftCardDetails.dealtitle }}</h2>
      <div class="business-info">
        <h3 class="business-title">
          <a :href="`/business/${giftCardDetails.business_id}`">{{ giftCardDetails.business_name }}</a> | 
          <i class="fas fa-star" style="color: #FFD705;"></i> 
          <strong style="color: black;">{{ averageRating }}</strong> 
          <strong style="color: black;"> (</strong>{{ reviews.length }} reviews<strong style="color: black;">)</strong>
        </h3>
      </div>
    </div>

    <div class="content-container">
      <div class="left-side">
        <!-- Image Viewer -->
        <div class="image-viewer">
          <button class="main-arrow left" v-if="images.length > 0" @click="prevImage" :disabled="selectedImageIndex === 0">&#9664;</button>
          <div class="main-image">
            <img v-if="images.length > 0" :src="images[selectedImageIndex]" alt="Main Image" />
            <img v-else src="@/assets/default-image.png" alt="Default Image" />
          </div>
          <button class="main-arrow right" v-if="images.length > 0" @click="nextImage" :disabled="selectedImageIndex === images.length - 1">&#9654;</button>
        </div>

        <!-- Thumbnails -->
        <div class="thumbnail-slider-container" v-if="images.length > 0">
          <button class="thumbnail-arrow left" v-if="images.length > thumbnailsPerPage" @click="prevBatch" :disabled="currentBatch === 0">
            <span>&#9664;</span>
          </button>
          <div class="thumbnail-slider">
            <div class="thumbnail-container">
              <div
                class="image-preview"
                v-for="(image, index) in visibleThumbnails"
                :key="index"
                @click="selectImage(index + currentBatch * thumbnailsPerPage)"
                :class="{ 'selected': index + currentBatch * thumbnailsPerPage === selectedImageIndex }"
              >
                <img :src="image" :alt="'Image ' + (index + 1)" />
              </div>
            </div>
          </div>
          <button class="thumbnail-arrow right" v-if="images.length > thumbnailsPerPage" @click="nextBatch" :disabled="(currentBatch + 1) * thumbnailsPerPage >= images.length">
            <span>&#9654;</span>
          </button>
        </div>

        <!-- Mobile Right Side - Only shows at 1200px or less -->
        <div class="right-side-mobile">
          <h2 class="select-option">Select Option:</h2>
          <div class="options-container" v-if="options.length > 0">
            <div 
              v-for="(option, index) in options" 
              :key="index"
              v-show="option.numberinstocktosell > 0" 
              class="option-item"
            >
              <div class="option-content">
                <input 
                  type="radio" 
                  :id="'option-mobile-' + index" 
                  name="option-mobile" 
                  :value="option.id" 
                  v-model="selectedOption"
                  :disabled="option.numberinstocktosell === 0"
                />
                <label :for="'option-mobile-' + index" class="option-label">
                  <div class="option-title">{{ option.dealname }}</div>
                  <div class="option-pricing">
                    <span class="original-price">${{ formatPrice(option.currentprice) }}</span>
                    <span class="new-price">${{ formatPrice(calculateCustomerPays(option)) }}</span>
                    <span class="discount">{{ option.discount }}% Off</span>
                  </div>
                </label>
              </div>
              <div class="option-details">
                <p>In Stock: {{ option.numberinstocktosell }}</p>
                <p>Max Purchase Amount: {{ option.maxperpurchase }}</p>
              </div>
              <div class="purchase-controls">
                <div class="quantity-control">
                  <button 
                    @click="decrementQuantity(option.id)" 
                    :disabled="!optionQuantities[option.id] || optionQuantities[option.id] <= 1"
                  >-</button>
                  <input 
                    type="number" 
                    v-model.number="optionQuantities[option.id]" 
                    min="1" 
                    :max="maxPurchaseAmount(option)"
                    @input="validateQuantity(option.id)"
                  />
                  <button 
                    @click="incrementQuantity(option.id)" 
                    :disabled="!optionQuantities[option.id] || optionQuantities[option.id] >= maxPurchaseAmount(option)"
                  >+</button>
                </div>
                <button 
                  class="add-to-cart" 
                  @click="addToCart(option.id)"
                  :class="{ 'remove-from-cart': cartStatuses[option.id] }"
                >
                  {{ getCartButtonText(option.id) }}
                </button>
              </div>
            </div>
          </div>

          <div class="action-buttons">
            <button v-if="isLoggedIn" class="become-fan" @click="toggleFanStatus">
              {{ isFan ? 'Unsubscribe' : 'Become a Fan' }}
            </button>
            <button v-if="isLoggedIn" class="signup-alerts" @click="openModal">
              Sign-Up for Alerts
            </button>
          </div>

          <div class="share-section">
            <h3>Share This Deal</h3>
            <div class="social-icons">
              <a href="#" class="social-icon" @click.prevent="shareViaEmail"><i class="fas fa-envelope"></i></a>
              <a href="#" class="social-icon" @click.prevent="shareOnFacebook"><i class="fab fa-facebook"></i></a>
              <a href="#" class="social-icon" @click.prevent="shareOnTwitter"><i class="fab fa-twitter"></i></a>
              <a href="#" class="social-icon" @click.prevent="shareOnPinterest"><i class="fab fa-pinterest"></i></a>
            </div>
          </div>
        </div>

        <!-- Nav Links for About, Fine Print, Reviews -->
        <div class="section-nav">
          <a href="#about">About</a>
          <a href="#fine-print">Fine Print</a>
          <a href="#where-to-redeem">Where to redeem</a>
          <a href="#reviews">Reviews</a>
        </div>
        <hr class="text-separator" />

        <!-- About Deal -->
        <div id="about" class="about-deal" v-if="giftCardDetails.dealdetails">
          <h2>About This Coupon</h2>
          <div v-html="giftCardDetails.dealdetails"></div>
        </div>

        <!-- Fine Print -->
        <div id="fine-print" class="fine-print" v-if="giftCardDetails.dealfineprint">
          <h2>Fine Print</h2>
          <hr class="text-separator" />
          <div v-html="giftCardDetails.dealfineprint"></div>
        </div>

        <!--Where to redeem section-->
        <WhereToRedeem
          :business-name="giftCardDetails.business_name"
          :locations="giftCardDetails.locations || []"
        />

        <!-- Customer Reviews -->
        <CustomerReviews
          :reviews="reviews"
          :averageRating="averageRating"
          :giftCardId="giftCardDetails.id"
          @review-added="addReview"
        />
      </div>

      <!-- Desktop Right Side - Shows above 1200px -->
      <div class="right-side">
        <h2 class="select-option">Select Option:</h2>
        <div class="options-container" v-if="options.length > 0">
          <div 
            v-for="(option, index) in options" 
            :key="index"
            v-show="option.numberinstocktosell > 0" 
            class="option-item"
          >
            <div class="option-content">
              <input 
                type="radio" 
                :id="'option-' + index" 
                name="option" 
                :value="option.id" 
                v-model="selectedOption"
                :disabled="option.numberinstocktosell === 0"
              />
              <label :for="'option-' + index" class="option-label">
                <div class="option-title">{{ option.dealname }}</div>
                <div class="option-pricing">
                  <span class="original-price">${{ formatPrice(option.currentprice) }}</span>
                  <span class="new-price">${{ formatPrice(calculateCustomerPays(option)) }}</span>
                  <span class="discount">{{ option.discount }}% Off</span>
                </div>
              </label>
            </div>
            <div class="option-details">
              <p>In Stock: {{ option.numberinstocktosell }}</p>
              <p>Max Purchase Amount: {{ option.maxperpurchase }}</p>
            </div>
            <div class="purchase-controls">
              <div class="quantity-control">
                <button 
                  @click="decrementQuantity(option.id)" 
                  :disabled="!optionQuantities[option.id] || optionQuantities[option.id] <= 1"
                >-</button>
                <input 
                  type="number" 
                  v-model.number="optionQuantities[option.id]" 
                  min="1" 
                  :max="maxPurchaseAmount(option)"
                  @input="validateQuantity(option.id)"
                />
                <button 
                  @click="incrementQuantity(option.id)" 
                  :disabled="!optionQuantities[option.id] || optionQuantities[option.id] >= maxPurchaseAmount(option)"
                >+</button>
              </div>
              <button 
                class="add-to-cart" 
                @click="addToCart(option.id)"
                :class="{ 'remove-from-cart': cartStatuses[option.id] }"
              >
                {{ getCartButtonText(option.id) }}
              </button>
            </div>
          </div>
        </div>

        <div class="action-buttons">
          <button v-if="isLoggedIn" class="become-fan" @click="toggleFanStatus">
            {{ isFan ? 'Unsubscribe' : 'Become a Fan' }}
          </button>
          <button v-if="isLoggedIn" class="signup-alerts" @click="openModal">
            Sign-Up for Alerts
          </button>
        </div>

        <div class="share-section">
          <h3>Share This Deal</h3>
          <div class="social-icons">
            <a href="#" class="social-icon" @click.prevent="shareViaEmail"><i class="fas fa-envelope"></i></a>
            <a href="#" class="social-icon" @click.prevent="shareOnFacebook"><i class="fab fa-facebook"></i></a>
            <a href="#" class="social-icon" @click.prevent="shareOnTwitter"><i class="fab fa-twitter"></i></a>
            <a href="#" class="social-icon" @click.prevent="shareOnPinterest"><i class="fab fa-pinterest"></i></a>
          </div>
        </div>
      </div>
    </div>

    <!-- Explore More Section -->
    <div class="explore-more-section">
      <h2>Explore More</h2>
      <div class="deals-grid-horizontal">
        <GiftCard
          v-for="(giftCard, index) in exploreMoreDeals"
          :key="index"
          :giftCardId="giftCard._id"
          :dealTitle="giftCard.dealtitle"
          :rating="giftCard.rating"
          :cardImage="getGiftCardImage(giftCard)"
          :originalPrice="giftCard.currentprice[0]"
          :discountPercentage="giftCard.discount[0]"
          :businessId="giftCard.business_id"
        />
      </div>
    </div>

    <!-- Sponsored Deals Section -->
    <div v-if="sponsoredDeals.length > 0" class="sponsored-deals-section">
      <h2>Sponsored Deals</h2>
      <div class="deals-grid-horizontal">
        <GiftCard
          v-for="(giftCard, index) in sponsoredDeals"
          :key="index"
          :giftCardId="giftCard._id"
          :dealTitle="giftCard.dealtitle"
          :rating="giftCard.rating"
          :cardImage="getGiftCardImage(giftCard)"
          :originalPrice="giftCard.currentprice[0]"
          :discountPercentage="giftCard.discount[0]"
          :businessId="giftCard.business_id"
        />
      </div>
    </div>

    <!-- Notification Modal -->
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
    <div class="modal" @click.stop>
      <h3>Subscribe to Notifications</h3>
      <p>Receive notifications when this business posts new coupons, gift cards, or updates.</p>
      <form @submit.prevent="submitSubscription">
        <div class="toggle-group">
          <div class="toggle-item">
            <label for="email">Email</label>
            <label class="switch">
              <input type="checkbox" id="email" v-model="subscription.email">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="toggle-item">
            <label for="text">Text</label>
            <label class="switch">
              <input type="checkbox" id="text" v-model="subscription.text">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="toggle-item">
            <label for="notifications">Notifications</label>
            <label class="switch">
              <input type="checkbox" id="notifications" v-model="subscription.notifications">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <p class="disclaimer">
          By subscribing to email or text alerts, you agree to receive communications from this business regarding promotions, updates, and offers. Message and data rates may apply. You can unsubscribe from these notifications at any time by updating your preferences in your account settings or contacting support.
        </p>
        <div class="modal-buttons">
          <button type="submit" class="submit-button">Submit</button>
        </div>
      </form>
    </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios';
import CustomerReviews from '@/components/Reviews/GiftCardReviews.vue'; 
import GiftCard from '@/components/GiftCard Components/GiftCard.vue'; 
import MetaManager from '../../components/MetaManager.vue';
import WhereToRedeem from '../../components/GiftCardPage/WhereToRedeem.vue';

export default {
  name: 'GiftCardPreview',
  components: {
    CustomerReviews,
    GiftCard,
    MetaManager,
    WhereToRedeem
  },
  data() {
    return {
      giftCardDetails: {
        dealtitle: '',
        category: '',
        dealdetails: '',
        dealfineprint: '',
        business_id: null,
      },
      images: [],
      selectedImageIndex: 0,
      currentBatch: 0,
      thumbnailsPerPage: 6,
      options: [], // Updated to hold multiple options
      selectedOption: null,
      purchaseQuantity: 1,
      isLoggedIn: false,
      message: '',
      fading: false,
      showModal: false,
      subscription: {
        email: false,
        text: false,
        notifications: false
      },
      isFan: false,
      reviews: [],
      averageRating: 0,
      relatedItems: [],
      relatedStartIndex: 0,
      relatedEndIndex: 1,
      displayedAds: [],
      isInCart: false,
      token: localStorage.getItem('token'),
      metaTitle: '',
      metaDescription: '',
      sponsoredDeals: [],
      optionQuantities: {}, // New property to track quantities per option
      cartStatuses: {}, // New property to track cart status per option
      initialOptionSet: false,
    };
  },
  computed: {
    visibleThumbnails() {
      const start = this.currentBatch * this.thumbnailsPerPage;
      const end = start + this.thumbnailsPerPage;
      return this.images.slice(start, end);
    },
    isMaxExceeded() {
      return this.purchaseQuantity > this.maxPurchaseAmount;
    },
    visibleRelatedItems() {
      return this.relatedItems.slice(this.relatedStartIndex, this.relatedEndIndex + 1);
    },
    cartButtonText() {
      return this.isInCart ? 'Remove from Cart' : 'Add to Cart';
    },
    formattedOriginalPrice() {
      return this.formatPrice(this.option?.currentprice);
    },
    formattedDiscountedPrice() {
      return this.formatPrice(this.calculateCustomerPays(this.option));
    },
  },
  watch: {
    options: {
      handler(newOptions) {
        // Set the first available option as selected when options are loaded
        if (!this.initialOptionSet && newOptions.length > 0) {
          const firstAvailableOption = newOptions.find(opt => opt.numberinstocktosell > 0);
          if (firstAvailableOption) {
            this.selectedOption = firstAvailableOption.id;
            this.initialOptionSet = true;
          }
        }
      },
      immediate: true
    },
    giftCardDetails: {
      handler() {
        this.$nextTick(() => {
          this.checkIfInCart();
        });
      },
      deep: true
    }
  },
  methods: {
    processDiscount(coupon) {
        const { discount, regularprice, spendamount, discounttype } = coupon;
        const isRegularPriceValid = regularprice[0] !== 0;
        const isSpendAmountValid = spendamount[0] !== 0;
  
        return {
          discount: discount[0],
          regularprice: isRegularPriceValid ? regularprice : null,
          spendamount: isSpendAmountValid ? spendamount : null,
          discounttype: discounttype[0]
        };
      },
      getGiftCardImage(deal) {
      // Check if image_links exists and is an array with at least one item
      if (Array.isArray(deal.image_links) && deal.image_links.length > 0) {
        return deal.image_links[0]; // Return the first image
      }
      // If image_links is a string, return it directly
      if (typeof deal.image_links === 'string') {
        return deal.image_links;
      }
      // If no valid image, return an empty string
      return '';
    },
    formatPrice(price) {
      return typeof price === 'number' ? price.toFixed(2) : '0.00';
    },
    incrementQuantity(optionId) {
      const selected = this.options.find(option => option.id === optionId);
      if (!this.optionQuantities[optionId]) {
        this.optionQuantities[optionId] = 1;
      }
      
      if (selected && this.optionQuantities[optionId] < this.maxPurchaseAmount(selected)) {
        this.optionQuantities[optionId]++;
      }
    },
    decrementQuantity(optionId) {
      if (!this.optionQuantities[optionId]) {
        this.optionQuantities[optionId] = 1;
      }
      
      if (this.optionQuantities[optionId] > 1) {
        this.optionQuantities[optionId]--;
      }
    },
    validateQuantity(optionId) {
      const selected = this.options.find(option => option.id === optionId);
      if (selected) {
        if (!this.optionQuantities[optionId] || this.optionQuantities[optionId] < 1) {
          this.optionQuantities[optionId] = 1;
        } else if (this.optionQuantities[optionId] > this.maxPurchaseAmount(selected)) {
          this.optionQuantities[optionId] = this.maxPurchaseAmount(selected);
        }
      }
    },
    shareOnFacebook() {
      try {
        const currentUrl = window.location.href;
        const encodedUrl = encodeURIComponent(currentUrl);
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        window.open(facebookUrl, '_blank', 'width=600,height=400,scrollbars=no,resizable=no,toolbar=no,menubar=no,location=no,directories=no,status=no');
      } catch (error) {
        console.error('Error sharing on Facebook:', error);
      }
    },

    shareViaEmail() {
      const subject = encodeURIComponent(this.giftCardDetails.dealtitle);
      const body = encodeURIComponent(`Check out this deal: ${this.giftCardDetails.dealtitle}\n\n${window.location.href}`);
      window.location.href = `mailto:?subject=${subject}&body=${body}`;
    },

    shareOnPinterest() {
      const url = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(window.location.href)}&media=${encodeURIComponent(this.images[0])}&description=${encodeURIComponent(this.giftCardDetails.dealtitle)}`;
      window.open(url, '_blank');
    },

    shareOnTwitter() {
      const text = encodeURIComponent(`Check out this deal: ${this.giftCardDetails.dealtitle}`);
      const url = `https://twitter.com/intent/tweet?text=${text}&url=${encodeURIComponent(window.location.href)}`;
      window.open(url, '_blank');
    },
    maxPurchaseAmount(option) {
      return Math.min(option.maxperpurchase, option.numberinstocktosell);
    },
    async fetchGiftCardData() {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user ? user.id : null;

      const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/get-gift-cards/data/${this.$route.params.id}`, {
        params: { userId }
      });
      const data = response.data;

      this.giftCardDetails = {
        dealtitle: data.dealtitle,
        category: data.category,
        dealdetails: data.dealdetails,
        dealfineprint: data.dealfineprint,
        business_id: data.business_id,
        business_name: data.business_name,
        locations: data.locations || [],
      };

      // Set meta title and description
      this.metaTitle = this.giftCardDetails.dealtitle;
      this.metaDescription = this.giftCardDetails.dealdetails 
        ? this.stripHtmlTags(this.giftCardDetails.dealdetails).substring(0, 160)
        : 'Check out this amazing gift card deal!';

      this.images = data.image_links || [];
      
      // Set all options
      this.options = data.dealname.map((dealName, index) => ({
        id: index,
        dealname: dealName,
        currentprice: parseFloat(data.currentprice[index]),
        discount: parseFloat(data.discount[index]),
        maxperpurchase: parseInt(data.maxperpurchase[index]),
        numberinstocktosell: parseInt(data.numberinstock[index]),
      }));
      
      // Set the first option as selected by default
      this.selectedOption = this.options.length > 0 ? this.options[0].id : null;

      this.reviews = data.reviews || [];
      this.updateAverageRating();
      this.isLoggedIn = !!localStorage.getItem('user');
      this.checkNotificationPreferences();
      this.checkIfInCart();
    } catch (error) {
      console.error('Error fetching gift card data:', error);
    }
  },
    stripHtmlTags(html) {
      const tmp = document.createElement('DIV');
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || '';
    },
    async fetchRelatedItems() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/related/giftcard/${this.$route.params.id}`);
        const { exploreMoreDeals, sponsoredDeals } = response.data;

        // Set Explore More and Sponsored Deals
        this.exploreMoreDeals = exploreMoreDeals;
        this.sponsoredDeals = sponsoredDeals;
      } catch (error) {
        console.error('Error fetching related gift cards:', error);
        // Set empty arrays if there's an error
        this.exploreMoreDeals = [];
        this.sponsoredDeals = [];
      }
    },
    async fetchSponsoredDeals() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/related/sponsored-giftcards/${this.$route.params.id}`);
        this.sponsoredDeals = response.data.sponsoredDeals;
      } catch (error) {
        console.error('Error fetching sponsored gift cards:', error);
        this.sponsoredDeals = [];
      }
    },
    prevRelatedItem() {
      if (this.relatedStartIndex > 0) {
        this.relatedStartIndex--;
        this.relatedEndIndex--;
      }
    },
    nextRelatedItem() {
      if (this.relatedEndIndex < this.relatedItems.length - 1) {
        this.relatedStartIndex++;
        this.relatedEndIndex++;
      }
    },
    prevImage() {
      if (this.selectedImageIndex > 0) {
        this.selectedImageIndex--;
      }
    },
    nextImage() {
      if (this.selectedImageIndex < this.images.length - 1) {
        this.selectedImageIndex++;
      }
    },
    prevBatch() {
      if (this.currentBatch > 0) {
        this.currentBatch--;
      }
    },
    nextBatch() {
      if ((this.currentBatch + 1) * this.thumbnailsPerPage < this.images.length) {
        this.currentBatch++;
      }
    },
    selectImage(index) {
      this.selectedImageIndex = index;
    },
    calculateCustomerPays(option) {
    if (!option || typeof option.currentprice !== 'number' || typeof option.discount !== 'number') {
          return 0;
        }
        return option.currentprice * (1 - option.discount / 100);
    },
    async saveGiftCard(optionId) {
      const selected = this.options.find(option => option.id === optionId);
      
      if (!selected) {
        console.error('Option not found');
        return;
      }

      const cartItemIdentifier = `${this.$route.params.id}-${optionId}`;
      let checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];

      if (checkoutCartItems.some(item => item.cartItemId === cartItemIdentifier)) {
        this.showMessage('This option is already in the cart');
        return;
      }

      const quantity = this.optionQuantities[optionId] || 1;
      const newItem = {
        cartItemId: cartItemIdentifier,
        giftCardId: this.$route.params.id,
        optionId: optionId,
        dealTitle: this.giftCardDetails.dealtitle,
        originalPrice: selected.currentprice,
        discount: selected.discount,
        quantity: quantity,
        businessId: this.giftCardDetails.business_id,
        name: selected.dealname,
        image_link: this.images
      };

      if (this.isLoggedIn) {
        const userId = JSON.parse(localStorage.getItem('user')).id;
        const token = localStorage.getItem('token');
        try {
          await axios.post(`${process.env.VUE_APP_API_URL}/api/shoppingCart/saveCart/${userId}`, newItem, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          checkoutCartItems.push(newItem);
          localStorage.setItem('checkoutCartItems', JSON.stringify(checkoutCartItems));
          window.dispatchEvent(new CustomEvent('update-checkout-cart', { detail: checkoutCartItems }));
          this.showMessage('Gift Card Saved');
          this.cartStatuses[optionId] = true;
        } catch (error) {
          console.error('Error saving gift card:', error);
        }
      } else {
        checkoutCartItems.push(newItem);
        localStorage.setItem('checkoutCartItems', JSON.stringify(checkoutCartItems));
        window.dispatchEvent(new CustomEvent('update-checkout-cart', { detail: checkoutCartItems }));
        this.showMessage('Gift Card Added to Cart');
        this.cartStatuses[optionId] = true;
      }
    },
    async checkIfInCart() {
      if (!this.giftCardDetails || !this.$route.params.id) {
        return;
      }
      const checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
      
      // Reset cart statuses
      this.cartStatuses = {};
      
      // Check each option's cart status
      this.options.forEach(option => {
        const cartItemIdentifier = `${this.$route.params.id}-${option.id}`;
        this.cartStatuses[option.id] = checkoutCartItems.some(item => item.cartItemId === cartItemIdentifier);
      });
    },
    async addToCart(optionId) {
      if (this.cartStatuses[optionId]) {
        await this.removeFromCart(optionId);
      } else {
        await this.saveGiftCard(optionId);
      }
    },
    async buyNow() {
      await this.saveGiftCard();
      await new Promise(resolve => setTimeout(resolve, 1000));
      if (!this.isLoggedIn) {
        this.$router.push('/login');
        window.scrollTo(0, 0);
        return;
      }
      this.$router.push('/checkout')
      window.scrollTo(0, 0);
    },
    showMessage(text) {
      this.message = text;
      this.fading = false;
      setTimeout(() => {
        this.fading = true;
        setTimeout(() => {
          this.message = '';
          this.fading = false;
        }, 1000); // Duration of the fade-out effect
      }, 2000); // Duration the message stays visible before fading out
    },
    subscribeToBusiness() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    toggleFanStatus() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.id) {
        this.showMessage('Please log in to update fan status.');
        return;
      }

      const userId = user.id;
      const giftCardId = this.$route.params.id;
      const token = localStorage.getItem('token');

      // Toggle the fan status
      const newFanStatus = !this.isFan;

      axios
        .post(`${process.env.VUE_APP_API_URL}/api/update-notifications`, {
          giftCardId,
          userId,
          isFan: newFanStatus
        }, {
          headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(() => {
          this.isFan = newFanStatus;

          // Update localStorage with the new fan status
          if (!user.notification_preferences) {
            user.notification_preferences = {};
          }

          if (!user.notification_preferences[this.giftCardDetails.business_id]) {
            user.notification_preferences[this.giftCardDetails.business_id] = {};
          }

          user.notification_preferences[this.giftCardDetails.business_id].isFan = newFanStatus;
          localStorage.setItem('user', JSON.stringify(user));
          this.showMessage(newFanStatus ? 'You are now a fan!' : 'Fan status removed.');
        })
        .catch(error => {
          console.error('Error updating fan status:', error);
        });
    },
    openModal() {
      this.showModal = true;
    },
    submitSubscription() {
      this.showModal = false;

      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.id) {
        this.showMessage('Please log in to update your subscription preferences.');
        return;
      }

      const userId = user.id;
      const giftCardId = this.$route.params.id;
      const token = localStorage.getItem('token');

      axios
        .post(`${process.env.VUE_APP_API_URL}/api/update-notifications`, {
          giftCardId,
          userId,
          notifications: {
            email: this.subscription.email,
            text: this.subscription.text,
            notifications: this.subscription.notifications
          }
        }, {
          headers: { 'Authorization': `Bearer ${token}` }
        })
        .then(() => {
          // Only update notifications in localStorage without changing `isFan`
          if (!user.notification_preferences) {
            user.notification_preferences = {};
          }

          user.notification_preferences[this.giftCardDetails.business_id] = {
            ...user.notification_preferences[this.giftCardDetails.business_id],
            email: this.subscription.email,
            text: this.subscription.text,
            notifications: this.subscription.notifications
          };

          localStorage.setItem('user', JSON.stringify(user));
          this.showMessage('Notification preferences updated.');
        })
        .catch(error => {
          console.error('Error updating subscription preferences:', error);
        });
    },
    getCartButtonText(optionId) {
      return this.cartStatuses[optionId] ? 'Remove from Cart' : 'Add to Cart';
    },
    async unsubscribe() {
      this.showModal = false;

      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!user || !user.id) {
        this.showMessage('Please log in to update your subscription preferences.');
        return;
      }

      const userId = user.id;
      const giftCardId = this.$route.params.id;

      try {
        await axios.put(`${process.env.VUE_APP_API_URL}/api/update-notifications/remove-preferences`, {
          giftCardId,
          userId,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });



        // Update localStorage to remove the business preferences
        const businessId = this.giftCardDetails.business_id;

        if (user.notification_preferences && user.notification_preferences[businessId]) {
          delete user.notification_preferences[businessId];
        }

        localStorage.setItem('user', JSON.stringify(user));
        this.isFan = false;

      } catch (error) {
        console.error('Error unsubscribing:', error);
      }
    },
    updateAverageRating() {
      if (this.reviews.length === 0) {
        this.averageRating = 0;
      } else {
        const total = this.reviews.reduce((sum, review) => sum + parseFloat(review.rating), 0);
        this.averageRating = (total / this.reviews.length).toFixed(1);
      }
    },
    addReview(newReview) {
      this.reviews.push(newReview);
      this.updateAverageRating();
    },
    checkNotificationPreferences() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.notification_preferences) {
        this.isFan = false;
        return;
      }

      const notificationPreferences = user.notification_preferences;
      const businessId = this.giftCardDetails.business_id;

      const userPreference = notificationPreferences[businessId];

      if (userPreference) {
        this.isFan = true;
        this.subscription.email = userPreference.email || false;
        this.subscription.text = userPreference.text || false;
        this.subscription.notifications = userPreference.notifications || false;
      } else {
        this.isFan = false;
      }
    },
    async removeFromCart(optionId) {
      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user ? user.id : null;
      const cartItemIdentifier = `${this.$route.params.id}-${optionId}`;

      // Find the option to get its name
      const selectedOption = this.options.find(option => option.id === optionId);
      const optionName = selectedOption ? selectedOption.dealname : null;

      if (userId) {
        try {
          await axios.put(`${process.env.VUE_APP_API_URL}/api/shoppingCart/remove-from-cart/${userId}`, {
            giftCardId: this.$route.params.id,
            name: optionName  // Changed to send the name instead of optionId
          }, {
            headers: { 'Authorization': `Bearer ${this.token}` }
          });
        } catch (error) {
          console.error('Error removing from cart on server:', error);
        }
      }

      let checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
      checkoutCartItems = checkoutCartItems.filter(item => item.cartItemId !== cartItemIdentifier);
      localStorage.setItem('checkoutCartItems', JSON.stringify(checkoutCartItems));
      window.dispatchEvent(new CustomEvent('update-checkout-cart', { detail: checkoutCartItems }));
      this.showMessage('Gift Card Removed from Cart');
      this.cartStatuses[optionId] = false;
    },
    async toggleCartItem() {
      if (this.isInCart) {
        await this.removeFromCart();
      } else {
        await this.addToCart();
      }
    },
  },
  async mounted() {
    await this.fetchGiftCardData();
    await this.fetchRelatedItems();
    await this.fetchSponsoredDeals();
    this.options.forEach(option => {
      this.optionQuantities[option.id] = 1;
    });
    window.addEventListener('update-checkout-cart', this.checkIfInCart);
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.notification_preferences && user.notification_preferences[this.giftCardDetails.business_id]) {
      const preferences = user.notification_preferences[this.giftCardDetails.business_id];
      this.isFan = !!preferences.isFan;
      this.subscription = {
        email: preferences.email || false,
        text: preferences.text || false,
        notifications: preferences.notifications || false
      };
    }
  },
  beforeUnmount() {
    window.removeEventListener('update-checkout-cart', this.checkIfInCart);
  },
};
</script>

<style scoped>
.step-preview {
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.content-container {
  display: flex;
  gap: 40px;
}

.right-side-mobile {
  display: none; 
}

.deal-header {
  margin-bottom: 30px;
  max-width: 700px;
}

.deal-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}


.left-side {
  flex: 1;
}

.right-side {
  position: -webkit-sticky;
  width: 600px;
  padding: 20px;
  position: sticky;
  top: 50px;
  align-self: flex-start;
}

.explore-more-section,
.sponsored-deals-section {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 50px;
}

.explore-more-section h2,
.sponsored-deals-section h2 {
  text-align: left;
  font-size: 1.3em;
  margin-bottom: 20px;
}

.business-title a{
  color: #757575;
  
}

.separator {
  width: 60%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
  box-sizing: border-box;
}

.text-separator{
  width: 100%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
  box-sizing: border-box;
}

.deals-grid-horizontal {
  display: flex;
  justify-content: flex-start;
  gap: 10px; /* Reduced gap */
  width: 100%;
  overflow: visible; /* Allow overflow to be visible */
}

.deals-grid-horizontal :deep(.gift-card) {
  flex: 0 0 calc(25% - 10px);
  max-width: calc(20% - 15px);
}

.section-nav {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.section-nav span {
  display: inline-block;
}

.section-nav a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  padding: 5px 0;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s;
}

.section-nav a:hover,
.section-nav a:focus {
  border-bottom-color: #4c6b30;
}

/* Ensure no default list styling is interfering */
.section-nav ul,
.section-nav li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.image-viewer {
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-image {
  width: 100%;
  height: 100%;
}

.main-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.main-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.main-arrow.left { left: 10px; }
.main-arrow.right { right: 10px; }

.thumbnail-slider-container {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  overflow-x: auto;
  padding-bottom: 10px;
}
.thumbnail-slider {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: calc(100% - 30px);
  box-sizing: border-box;
}

.thumbnail-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.image-preview {
  flex: 0 0 80px;
  width: 80px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.image-preview.selected {
  border-color: #4c6b30;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-arrow {
  width: 10px;
  height: 110px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  z-index: 1;
  position: relative;
  box-sizing: border-box;
}

.thumbnail-arrow.left {
  margin-right: 5px;
}

.thumbnail-arrow.right {
  margin-left: 0px;
}

.fine-print {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 50px;
  box-sizing: border-box;
}

.about-deal h2, .fine-print h2, .customer-reviews h2 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
}

.option-content {
  display: flex;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.option-label {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.option-pricing {
  display: flex;
  align-items: center;
  margin-top: 10px;
  box-sizing: border-box;
}

.original-price {
  color: gray;
  margin-right: 10px;
  text-decoration: line-through;
  box-sizing: border-box;
}

.new-price {
  font-size: 18px;
  font-weight: bold;
  color: green;
  box-sizing: border-box;
}

.discount {
  background-color: #d4edda;
  color: green;
  padding: 3px 5px;
  border-radius: 4px;
  margin-left: 5px;
  box-sizing: border-box;
}

.option-stock {
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.option-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.option-quantity input[type="number"] {
  width: 50px;
  padding: 5px;
  text-align: center;
  box-sizing: border-box;
}

.button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.buy-now,
.add-to-cart,
.become-fan {
  width: 58%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.become-fan{
  width: 48% !important; 
}

.buy-now {
  background-color: #4C6B30;
  color: white;
}

.buy-now:hover {
  background-color: #6E9F49;
  cursor: pointer;
}

.add-to-cart {
  background-color: #6E9F49;
  color:white;
}

.add-to-cart:hover {
  background-color: #A3BF8F;
  cursor: pointer;
}

.become-fan {
  background-color: #8DB36D;
  color: white;
}

.become-fan:hover {
  background-color: #A3BF8F;
  cursor: pointer;
}

.share-section {
  margin-top: 20px;
  text-align: left;
  box-sizing: border-box;
}

.share-section h3 {
  margin-bottom: 10px;
  font-size: 16px;
  box-sizing: border-box;
}

.social-icons {
  display: flex;
  gap: 10px;
  box-sizing: border-box;
}

.social-icon {
  font-size: 24px;
  color: #4C6B30;
  text-decoration: none;
  box-sizing: border-box;
}

.social-icon:hover {
  color: #6E9F49;
  box-sizing: border-box;
}

.ads-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 18%;
  box-sizing: border-box;
}

.ads-section h3 {
  margin-top: 20px;
  text-align: left;
  margin-left: 3.5%;
  box-sizing: border-box;
}

.ad-item {
  margin-bottom: 10px;
  box-sizing: border-box;
}

.ad-item img {
  max-width: 100%;
  height: auto;
  box-sizing: border-box;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 450px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.toggle-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.toggle-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #4C6B30;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.checkbox-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.checkbox-group div {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.submit-button,
.unsubscribe-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  box-sizing: border-box;
}

.submit-button {
  background-color: #4C6B30;
  color: white;
  box-sizing: border-box;
}

.submit-button:hover {
  background-color: #6E9F49;
  box-sizing: border-box;
}

.unsubscribe-button {
  background-color: #f44336;
  color: white;
  box-sizing: border-box;
}

.unsubscribe-button:hover {
  background-color: #d32f2f;
  box-sizing: border-box;
}

.related-items {
  margin-top: 50px;
  box-sizing: border-box;
}

.related-slider {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.related-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.related-arrow.left {
  margin-right: 10px;
  box-sizing: border-box;
}

.related-arrow.right {
  margin-left: 10px;
  box-sizing: border-box;
}

.related-item-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  overflow: hidden;
  box-sizing: border-box;
}

.gift-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.gift-card img {
  max-width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
}

.gift-card h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.gift-card p {
  font-size: 1em;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.gift-card .button {
  background-color: #4C6B30;
  color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  text-decoration: none;
  font-size: 1em;
  box-sizing: border-box;
}

.gift-card .button:hover {
  background-color: #6E9F49;
  box-sizing: border-box;
}

.mobile-options-list {
  display: none; /* Hide the mobile options list by default */
}

.ads-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 21%;
  box-sizing: border-box;
}

.ads-section h3 {
  margin-top: 20px;
  text-align: left;
  margin-left: 3.5%;
  box-sizing: border-box;
}

.ad-item {
  margin-bottom: 10px;
  box-sizing: border-box;
}

.deals-grid-horizontal :deep(.gift-card) {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.deals-grid-horizontal :deep(.gift-card:hover) {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.business-info {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.right-side h2 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #333;
}

.options-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.option-item[style*="display: none"] {
  display: none !important;
}

.option-item {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
}

.option-item:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.option-content {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.option-label {
  margin-left: 10px;
  flex: 1;
}

.option-title {
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.option-pricing {
  display: flex;
  align-items: center;
  gap: 10px;
}

.original-price {
  color: #999;
  text-decoration: line-through;
  font-size: 14px;
}

.new-price {
  font-size: 18px;
  font-weight: bold;
  color: #4C6B30;
}

.discount {
  background-color: #4C6B30;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.option-stock {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.option-quantity {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.option-quantity input[type="number"] {
  width: 60px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.buy-now,
.add-to-cart,
.become-fan {
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buy-now {
  background-color: #4C6B30;
  color: white;
}

.buy-now:hover {
  background-color: #45a049;
}

.add-to-cart {
  background-color: #6E9F49;
  color: white;
}

.add-to-cart:hover {
  background-color: #5C8A3C;
}

.become-fan {
  background-color: #8DB36D;
  color: white;
}

.become-fan:hover {
  background-color: #7A9E5C;
}

.share-section {
  margin-top: 20px;
}

.share-section h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icon {
  font-size: 24px;
  color: #4C6B30;
  text-decoration: none;
}

.social-icon:hover {
  color: #6E9F49;
}

.explore-more-section,
.sponsored-deals-section {
  margin-top: 50px;
}

.explore-more-section h2,
.sponsored-deals-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.deals-grid-horizontal {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding-bottom: 20px;
}

.deals-grid-horizontal :deep(.gift-card) {
  flex: 0 0 calc(25% - 15px);
  max-width: calc(25% - 15px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.deals-grid-horizontal :deep(.gift-card:hover) {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
}

.toggle-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.toggle-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4C6B30;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
}

.submit-button,
.unsubscribe-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.submit-button {
  background-color: #4C6B30;
  color: white;
}

.submit-button:hover {
  background-color: #6E9F49;
}

.unsubscribe-button {
  background-color: #f44336;
  color: white;
}

.unsubscribe-button:hover {
  background-color: #d32f2f;
}

.select-option {
  font-size: 18px;
  margin-bottom: 15px;
  color: #333;
}

.option-details {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}


.purchase-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.quantity-control {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.quantity-control button {
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}

.quantity-control input {
  width: 40px;
  text-align: center;
  border: none;
  font-size: 16px;
  -moz-appearance: textfield;
}

.quantity-control input::-webkit-outer-spin-button,
.quantity-control input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.signup-alerts {
  width: 48%;
  background-color: #4C6B30;
  color: white;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
  margin-left: 4%;
}

.signup-alerts:hover {
  background-color: #6E9F49;
}

@media (max-width: 1200px) {
  .content-container {
    flex-direction: column;
  }

  .left-side {
    display: flex;
    flex-direction: column;
  }


  .right-side {
    display: none;
  }

  .right-side-mobile {
    display: block;
    width: 100%;
    padding: 20px 0;
    margin: 30px 0;
  }

  .deals-grid-horizontal :deep(.gift-card) {
    flex: 0 0 calc(33.33% - 15px);
    max-width: calc(33.33% - 15px);
  }

  .left-side {
    width: 100%;
  }
}

@media (max-width: 1024px){
  .content-container{
    flex-direction: column;
  }

  .deal-header {
    margin-top: 250px;
  }

  .deal-title {
    font-size: 22px;
  }

  .business-title {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .step-preview {
    padding: 10px;
  }

  .deal-title {
    font-size: 20px;
  }

  .image-viewer {
    height: 300px;
  }

  .section-nav {
    flex-wrap: wrap;
  }

  .deals-grid-horizontal :deep(.gift-card) {
    flex: 0 0 calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
}

@media (max-width: 480px) {
  .deal-title {
    font-size: 18px;
  }

  .image-viewer {
    height: 250px;
  }

  .option-pricing {
    flex-direction: column;
    align-items: flex-start;
  }

  .deals-grid-horizontal :deep(.gift-card) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .modal {
    width: 95%;
  }

  .deal-header {
    margin-top: 340px;
  }
}
</style>