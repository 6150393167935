<template>
  <div class="account-page">
    <div class="sidebar-container">
      <div class="sidebar">
        <div class="sidebar-header">
          <h3>Hi, {{ firstName }}</h3>
        </div>
        <ul class="sidebar-menu">
          <li v-for="(item, index) in menuItems" :key="index">
            <router-link v-if="item.route" :to="item.route" class="sidebar-link">
              <i :class="item.icon"></i> {{ item.text }}
            </router-link>
            <a v-else @click="handleMenuItemClick(item)" class="sidebar-link">
              <i :class="item.icon"></i> {{ item.text }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-section">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountPage',
  data() {
    return {
      user: null,
      menuItems: [
        { text: 'Orders', icon: 'fas fa-box', route: '/account/orders' },
        { text: 'Coupon Bag', icon: 'fas fa-shopping-bag', route: '/account/shopping-bag' },
        { text: 'Notifications', icon: 'fas fa-bell', route: '/account/notifications' },
        { text: 'Reviews', icon: 'fas fa-star', route: '/account/reviews' },
        { text: 'Personal Info', icon: 'fas fa-user', route: '/account/personal-data' },
        { text: 'Change Password', icon: 'fas fa-lock', route: '/account/password-change' },
        { text: 'Logout', icon: 'fas fa-sign-out-alt', action: 'logout' }
      ]
    };
  },
  computed: {
    firstName() {
      return this.user ? this.user.fullName.split(' ')[0] : 'Shopper';
    }
  },
  created() {
    this.loadUserData();
  },
  methods: {
    loadUserData() {
      const userData = localStorage.getItem('user');
      if (userData) {
        this.user = JSON.parse(userData);
        if (this.user.id) {
          this.menuItems = this.menuItems.map(item => ({
            ...item,
            route: item.route ? item.route.replace('/account', `/account/${this.user.id}`) : null
          }));
        }
      } else {
        console.warn('User data not found in localStorage');
      }
    },
    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('checkoutCartItems');
        localStorage.removeItem('token');
        localStorage.removeItem('shoppingBagItems');
        localStorage.removeItem('billingDetails');
        window.location.reload();
        this.$router.push('/login');
    },
    handleMenuItemClick(item) {
      if (item.action === 'logout') {
        this.logout();
      }
    }
  }
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css');

.account-page {
  display: flex;
  padding: 20px;
}

.sidebar-container {
  width: 300px;
  padding: 63px;
}

.sidebar {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.sidebar-header {
  text-align: center;
  margin-bottom: 20px;
}

.sidebar-header h3 {
  font-size: 1.2em;
  margin: 0;
  color: #333;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu li {
  margin-bottom: 10px;
}

.sidebar-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 0.9em;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
  cursor: pointer;
}

.sidebar-link:hover {
  background-color: #e0e0e0;
}

.sidebar-link i {
  margin-right: 10px;
  width: 20px;
  text-align: center;
}

.content-section {
  flex-grow: 1;
  padding: 20px;
}

@media (max-width: 1024px){
  .account-page{
    padding-top: 200px;
  }
}

@media (max-width: 768px) {
  .account-page {
    flex-direction: column;
    margin-top: 150px;
  }

  .sidebar-container {
    width: 90%;
    padding: 0 0 20px 0;
  }

  .content-section {
    width: 90%;
  }
}
</style>