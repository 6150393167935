<!-- WhereToRedeem.vue -->
<template>
    <div id="where-to-redeem" class="where-to-redeem">
      <h2>Where to redeem</h2>
      <div v-if="locations && locations.length > 0" class="location-content">
        <div class="map-wrapper">
          <GoogleMap :address="selectedLocation || locations[0]" />
        </div>
        <div class="location-list" v-if="locations.length > 1">
          <div 
            v-for="(location, index) in locations" 
            :key="index"
            class="location-item"
            :class="{ 'active': selectedLocation === location }"
            @click="selectedLocation = location"
          >
            <div class="location-name">{{ businessName }}</div>
            <div class="location-address">{{ location }}</div>
          </div>
        </div>
        <div v-else class="single-location">
          <div class="location-name">{{ businessName }}</div>
          <div class="location-address">{{ locations[0] }}</div>
        </div>
      </div>
      <div v-else class="no-locations">
        No redemption locations available
      </div>
    </div>
  </template>
  
  <script>
  import GoogleMap from '../New Business Profile/GoogleMap.vue';
  
  export default {
    name: 'WhereToRedeem',
    components: {
      GoogleMap
    },
    props: {
      businessName: {
        type: String,
        required: true
      },
      locations: {
        type: Array,
        required: true,
        default: () => []
      }
    },
    data() {
      return {
        selectedLocation: null
      }
    },
    watch: {
      locations: {
        immediate: true,
        handler(newLocations) {
          if (newLocations && newLocations.length > 0) {
            this.selectedLocation = newLocations[0];
          }
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .where-to-redeem {
    margin: 30px 0;
    padding: 20px 0;
  }
  
  .where-to-redeem h2 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .location-content {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .map-wrapper {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
  }
  
  .location-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 20px 20px;
  }
  
  .location-item {
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .location-item:hover {
    background-color: #f5f5f5;
    border-color: #4C6B30;
  }
  
  .location-item.active {
    border-color: #4C6B30;
    background-color: #f5f5f5;
  }
  
  .single-location {
    padding: 15px 20px;
  }
  
  .location-name {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .location-address {
    color: #666;
    font-size: 14px;
  }
  
  .no-locations {
    text-align: center;
    padding: 20px;
    color: #666;
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .where-to-redeem {
      margin: 20px 0;
    }
    
    .map-wrapper {
      height: 250px;
    }
  }

  @media (max-width: 480px){
    .location-list{
      padding-top: 60px;
    }
  }
  </style>