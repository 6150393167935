<template>
  <header class="header">
    <div class="header-content">
      <!-- Left Content: Slogan and Swirly Buttons -->
      <div class="left-content">
        <div class="slogan">
          <h2>SHOP.</h2>
          <h2>SAVE.</h2>
          <h2>REPEAT.</h2>
        </div>
        <!-- Swirly Buttons -->
        <div class="swirly-buttons">
          <div class="swirly-button" @click="scrollToSection('trending-gift-cards')">
            <img src="@/assets/swirl.png" alt="Swirl" class="swirl-img" />
            <span class="swirl-text">Trending Gift Cards</span>
          </div>
          <div class="swirly-button" @click="scrollToSection('all-gift-cards')">
            <img src="@/assets/swirl.png" alt="Swirl" class="swirl-img" />
            <span class="swirl-text">View All Gift Cards</span>
          </div>
          <div class="swirly-button" @click="scrollToSection('trending-coupons')">
            <img src="@/assets/swirl.png" alt="Swirl" class="swirl-img" />
            <span class="swirl-text">Trending Coupons</span>
          </div>
        </div>
      </div>

      <!-- Separator Balls -->
      <div class="separator-balls">
        <img src="@/assets/seperator-balls.png" alt="Separator" class="separator-img" />
      </div>

      <!-- Right Content: Computer with Slider Inside -->
      <div class="right-content">
        <div class="computer-frame" @click="goToRegister">
          <!-- Slider inside the computer frame -->
          <div class="slider" @click.stop>
            <a :href="images[currentImageIndex]?.link || 'https://starkshopper.com'" target="_blank">
              <img
                :src="images[currentImageIndex]?.src || require('@/assets/logo.png')"
                alt="Slider Image"
                class="slider-img"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HeaderComponent',
  data() {
    return {
      images: [], // This will store images fetched from the backend
      currentImageIndex: 0, // Tracks the current image in the slider
    };
  },
  created() {
    this.fetchSliderAds(); // Fetch advertisements when component is created
    this.startImageSlider();
  },
  beforeUnmount() {
    this.stopImageSlider(); // Stop the interval when the component is destroyed
  },
  methods: {
    async fetchSliderAds() {
      const location = localStorage.getItem('selectedLocation')
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/advertisements/slider-images`, {
          params:{
            location: location
          }
        });
        const ads = response.data;
        if (ads.length > 0) {
          this.images = ads.map(ad => ({
            src: ad.image_url,
            link: ad.link_url,
          }));
        }
      } catch (error) {
        console.error('Error fetching slider advertisements:', error);
      }
    },
    startImageSlider() {
      this.sliderInterval = setInterval(() => {
        this.currentImageIndex =
          (this.currentImageIndex + 1) % (this.images.length || 1);
      }, 5000); // Change image every 5 seconds
    },
    stopImageSlider() {
      if (this.sliderInterval) {
        clearInterval(this.sliderInterval);
      }
    },
    goToRegister() {
      this.$router.push('/register');
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
  },
  watch: {
    // Watch for changes in localStorage selectedLocation
    '$localStorage.selectedLocation'() {
      this.selectedLocation = localStorage.getItem('selectedLocation');
      this.fetchSliderAds();
    }
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  background-color: #F2FDF2;
  background-image: url('@/assets/blob-scene-haikei-4.svg'); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
  min-height: 40vh;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1400px;
}

.left-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 2rem;
  margin-left: 10%;
}

.slogan h2 {
  color: #4caf50;
  font-size: 3rem;
  margin: 0;
  line-height: 1.2;
}

.swirly-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  margin-top: 2rem;
}

.swirly-button {
  width: 150px;
  height: 150px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.swirl-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swirl-text {
  position: absolute;
  font-size: 0.9rem;
  font-weight: bold;
  color: #000;
  text-align: center;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 1px 1px 2px rgba(255,255,255,0.5);
}

.separator-balls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5rem;
}

.separator-img {
  height: 200px;
  object-fit: contain;
}

.right-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 5%;
}

.computer-frame {
  background-image: url('@/assets/computer-2.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 700px;
  height: 500px;
  position: relative;
  cursor: pointer;
}

.slider {
  width: 65%;
  height: 55%;
  position: absolute;
  top: 30%;
  left: 16%;
  overflow: hidden;
}

.slider-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Adjustments for screen sizes between 1024px and 1500px */
@media (min-width: 1024px) and (max-width: 1500px) {
  .header {
    padding: 1.5rem;
    background-size: 100%;
    background-position: top center;
  }

  .header-content {
    max-width: 1200px;
  }

  .left-content {
    margin-left: 2%;
    padding-right: 1rem;
  }

  .slogan h2 {
    font-size: 3rem;
  }

  .swirly-buttons {
    justify-content: flex-start;
    gap: 1.5rem;
  }

  .right-content {
    margin-right: 2%;
  }

  .computer-frame {
    width: 600px;
    height: 500px;
  }

  .slider {
    width: 60%;
    height: 50%;
    top: 27%;
    left: 21%;
  }
}

@media (max-width: 1600px) {
  .header {
    padding: 1rem;
    background-size: cover; /* Ensure full background covers */
  }

  .header-content {
    max-width: 1600px; /* Reduced max-width for smaller screens */
  }

  .left-content {
    flex-direction: column;
    align-items: center;
    margin-left: 5%; /* Align centrally */
    padding-right: 1rem;
  }

  .slogan h2 {
    font-size: 2.5rem; /* Slightly smaller font */
  }

  .swirly-buttons {
    gap: 1rem;
  }

  .computer-frame {
    width: 550px;
    height: 450px;
  }

  .slider {
    width: 60%;
    height: 50%;
    top: 28%;
    left: 20%;
  }

  .separator-balls {
    margin: 0 3rem; /* Reduce margin to avoid overflow */
  }
}

@media (max-width: 1400px) {
  .header {
    padding: 1rem;
    background-size: cover; /* Ensure full background covers */
  }

  .header-content {
    max-width: 900px; /* Reduced max-width for smaller screens */
  }

  .left-content {
    flex-direction: column;
    align-items: center;
    margin-left: 0; /* Align centrally */
    padding-right: 0;
  }

  .slogan h2 {
    font-size: 2.5rem; /* Slightly smaller font */
  }

  .swirly-buttons {
    gap: 1rem;
  }

  .computer-frame {
    width: 550px;
    height: 450px;
  }

  .slider {
    width: 60%;
    height: 50%;
    top: 28%;
    left: 20%;
  }

  .separator-balls {
    margin: 0 3rem; /* Reduce margin to avoid overflow */
  }
}

@media (max-width: 1260px) {
  .header {
    padding: 1rem;
    background-size: cover; /* Ensure full background covers */
  }

  .header-content {
    max-width: 700px; /* Reduced max-width for smaller screens */
  }

  .left-content {
    flex-direction: column;
    align-items: center;
    margin-left: 0; /* Align centrally */
    padding-right: 0;
  }


  .slogan h2 {
    font-size: 2.5rem; /* Slightly smaller font */
  }

  .swirly-buttons {
    gap: 1rem;
  }

  .computer-frame {
    width: 450px;
    height: 350px;
  }

  .slider {
    width: 50%;
    height: 50%;
    top: 30%;
    left: 25%;
  }

  .separator-balls {
    margin: 0 3rem; /* Reduce margin to avoid overflow */
  }
}

/* Responsive for smaller screens */
@media (max-width: 1024px) {
  .header {
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
    padding-left: 1rem;
    background-image: none;
  }

  .blob-element {
    display: none;
  }

  .header-content {
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
  }

  .left-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 1rem;
  }

  .slogan h2 {
    font-size: 2.5rem;
    margin: 0.5rem 0;
  }

  .swirly-buttons {
    justify-content: center;
    gap: 1rem;
    width: 100%;
  }

  .swirly-button {
    width: 130px;
    height: 130px;
  }

  .right-content {
    width: 100%;
    margin-right: 0;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
  }

  .computer-frame {
    width: 150vw;
    height: 510px;
  }

  .slider {
    width: 55%;
    height: 55%;
    top: 32%;
    left: 21%;
  }

  .separator-balls {
    display: none;
  }
}

/* Mobile adjustments */
@media (max-width: 600px) {
  .swirly-buttons {
    flex-direction: column;
    align-items: center;
  }

  .swirly-button {
    width: 120px;
    height: 120px;
  }

  .computer-frame {
    width: 85vw;
    height: 64vw;
  }

  .slider {
    width: 55%;
    height: 55%;
    top: 32%;
    left: 21%;
  }
}

@media (max-width: 480px) {
  .header {
    margin-top: 300px;
  }
}
</style>
