<template>
  <div class="earning-reports-container">
    <div class="content">
      <div class="text-section">
        <h2>Earning Reports</h2>
        <div class="main-number">${{ totalSales }}</div>
        <p class="description">
          The chart below shows the monthly earnings for the year.
        </p>
      </div>
      <div class="chart-container">
        <BarChart v-if="shouldRenderChart" :chart-data="formattedChartData" :options="chartOptions" />
        <p v-if="chartError">{{ chartError }}</p>
      </div>
    </div>
  </div>
</template>


<script>
import { defineComponent, ref, watch, nextTick } from 'vue';
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default defineComponent({
  name: 'EarningReports',
  components: {
    BarChart
  },
  props: {
    totalSales: String,
    chartData: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    const shouldRenderChart = ref(false);
    const chartError = ref(null);
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0 // Disable animations
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 2000,
            callback: function(value) {
              return '$' + value;
            }
          },
          grid: {
            display: true,
            drawBorder: false,
            color: function(context) {
              if (context.tick.value === 0) {
                return 'rgba(0, 0, 0, 0.1)';
              }
              return 'rgba(0, 0, 0, 0.05)';
            }
          }
        },
        x: {
          grid: {
            display: false,
            drawBorder: false,
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: function(context) {
              return '$' + context.parsed.y.toFixed(2);
            }
          }
        }
      }
    };

    const formattedChartData = ref(null);

    watch(() => props.chartData, async (newChartData) => {
      if (newChartData && newChartData.datasets && Array.isArray(newChartData.datasets)) {
        formattedChartData.value = {
          ...newChartData,
          datasets: newChartData.datasets.map(dataset => ({
            ...dataset,
            backgroundColor: '#4C6B30',
            hoverBackgroundColor: '#6E9F49',
            borderColor: '#4C6B30',
            borderWidth: 1,
            borderRadius: 4,
            barPercentage: 0.5,
            categoryPercentage: 0.8
          }))
        };
        await nextTick();
        shouldRenderChart.value = true;
      } else {
        chartError.value = 'Invalid chart data structure. Please check the data format.';
      }
    }, { immediate: true });

    return {
      shouldRenderChart,
      chartError,
      chartOptions,
      formattedChartData
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.shouldRenderChart = true;
    });
  },
  errorCaptured(err, vm, info) {
    console.error('Error in component:', err, info);
    this.chartError = 'An error occurred while rendering the chart. Please check console for details.';
    return false;
  }
});
</script>


<style scoped>
.earning-reports-container {
  display: flex;
  flex-direction: column;
  padding: 1.25em;
  border-radius: 0.5em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 1.875em;
  width: 100%;
  box-sizing: border-box;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.text-section {
  margin-bottom: 1em;
  box-sizing: border-box;
}

.text-section h2 {
  margin: 0;
  color: #333;
}

.main-number {
  font-size: 2.5em;
  font-weight: bold;
}

.description {
  color: #666;
}

.chart-container {
  width: 100%;
  height: 100%;
  max-height: 600px !important;
  margin-bottom: 1em;
  position: relative;
  box-sizing: border-box;
}

.chart-container canvas {
  max-height: 400px !important;
  width: 100% !important;
  height: 50% !important;
}

@media (max-width: 1024px) {
  .chart-container {
    height: 20em;
  }

  .chart-container canvas {
    max-height: 20em;
  }

  .earning-reports-container{
    padding-bottom: 80px;
  }
}

@media (max-width: 768px) {
  .earning-reports-container {
    align-items: flex-start;
  }

  .text-section, .chart-container {
    width: 100%;
  }

  .chart-container {
    height: 380px;
  }

  .chart-container canvas {
    max-height: 480px;
    height: 100%;
  }
}

@media (max-width: 480px) {
  .main-number {
    font-size: 2em;
  }

  .description {
    font-size: 0.875em;
  }

  .chart-container {
    height: 100%;
    max-height: 500px;
  }

  .chart-container canvas {
    max-height: 480px;
    height: 100%;
  }
  .earning-reports-container{
    padding-bottom: 20px;
  }
}

</style>
