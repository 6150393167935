<template>
  <div class="notification-container">
    <!-- Token Count Header -->
    <div class="token-header">
      <p>Tokens Left:</p>
      <h2>{{ tokensLeft }}</h2>
<!--       <button class="get-more-button" @click="getMoreTokens">Get More</button> -->
    </div>
    
    <!-- Form Content -->
    <div class="content-container">
      <!-- Choose Notification Type -->
      <div class="form-group">
        <label for="notificationType">Choose a notification type</label>
        <select v-model="notificationType" id="notificationType" @change="calculateTokenCost">
          <option value="notification">Site Notification</option>
          <option value="email">Email</option>
           <option value="text">Text</option> 
        </select>
      </div>

      <!-- From (Business Name) -->
      <div class="form-group">
        <label for="from">From</label>
        <input type="text" v-model="from" id="from" readonly>
      </div>

      <!-- Title -->
      <div class="form-group" v-if="notificationType !== 'text'">
        <label for="title">Title</label>
        <input type="text" v-model="title" id="title">
      </div>

      <!-- Message Body (Quill Editor) -->
      <div class="form-group">
        <label for="messageBody">Message</label>
        <div id="messageEditor"></div>
      </div>

      <!-- Token Cost -->
      <div class="form-group">
        <label for="tokenCost">Token cost</label>
        <input type="number" v-model="tokenCost" id="tokenCost" readonly>
      </div>

      <!-- Buttons -->
      <div class="button-group">
        <button class="send-button" @click="sendNotification" :disabled="isSending || notificationSent">Send</button>
        <button class="cancel-button" @click="cancelNotification">Cancel</button>
      </div>
    </div>

    <!-- Loading Overlay -->
    <div v-if="isSending || notificationSent" class="loading-overlay">
      <div v-if="isSending" class="loader"></div>
      <p v-if="isSending">Sending notification... Do not refresh or close this page.</p>
      <p v-if="notificationSent" class="success-message">Notification sent to fans!</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import axios from 'axios';

export default {
  setup() {
    const tokensLeft = ref(0);
    const numberOfFans = ref(0);
    const notificationType = ref('notification');
    const from = ref('');
    const title = ref('');
    const tokenCost = ref(1);
    const isSending = ref(false);
    const notificationSent = ref(false);
    let quill;

    watch(notificationType, (newType) => {
      if (newType === 'text') {
        title.value = ''; // Clear title for text messages
      }
      calculateTokenCost();
    });

    onMounted(async () => {
      const toolbarOptions = [
        ['bold', 'italic', 'underline'], // basic text formatting
        [{ 'list': 'ordered'}, { 'list': 'bullet' }], // lists
        [{ 'align': [] }] // text alignment
      ];
      quill = new Quill('#messageEditor', {
        theme: 'snow',
        modules: {
          toolbar: toolbarOptions,
          clipboard:{
            matchVisual: false
          }
        },
        formats: [
          'bold',
          'italic',
          'underline',
          'list',
          'align'
        ]
      });

      await fetchBusinessName();
      await fetchTokensLeft(); // Fetch tokens when component mounts
    });

    // Fetch the business name
    const fetchBusinessName = async () => {
      try {
        const businessId = JSON.parse(localStorage.getItem('user')).business_id;
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/businesses/business-name/${businessId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        from.value = response.data.businessName;
      } catch (error) {
        console.error('Error fetching business name:', error);
        from.value = 'Unknown Business';
      }
    };

    // Fetch the number of tokens left for the business
    const fetchTokensLeft = async () => {
      try {
        const businessId = JSON.parse(localStorage.getItem('user')).business_id;
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/notifications/tokens-left`, {
          businessId
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        tokensLeft.value = response.data.tokensLeft;
      } catch (error) {
        console.error('Error fetching tokens left:', error);
      }
    };

    // Fetch the number of fans for the business
    const fetchNumberOfFans = async () => {
      try {
        const businessId = JSON.parse(localStorage.getItem('user')).business_id;
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/notifications/notification-fans`, {
          businessId
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        numberOfFans.value = response.data.numberOfSubscribers;
      } catch (error) {
        console.error('Error fetching number of fans:', error);
      }
    };

    // Calculate the token cost based on notification type and number of fans
    const calculateTokenCost = async () => {
      await fetchNumberOfFans(); // Fetch the number of fans whenever the notification type changes

      if (notificationType.value === 'notification') {
        tokenCost.value = 1;
      } else if (notificationType.value === 'email') {
        tokenCost.value = Math.ceil(numberOfFans.value / 50);
      } else if (notificationType.value === 'text') {
        tokenCost.value = Math.ceil(numberOfFans.value / 50) * 2;
      }
    };

    // Watch for changes to notificationType and recalculate the token cost
    watch(notificationType, () => {
      calculateTokenCost();
    });

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const sendNotification = async () => {
      isSending.value = true;
      const messageContent = quill.root.innerHTML;
      const businessId = JSON.parse(localStorage.getItem('user')).business_id;
      const token = localStorage.getItem('token');

      try {
        await delay(4000);

        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/notifications/new-notification`, {
          businessId,
          title: notificationType.value === 'text' ? '' : title.value,
          messageBody: messageContent,
          tokensCost: tokenCost.value,
          notificationType: notificationType.value
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        console.log('Notification sent successfully:', response.data);
        notificationSent.value = true;

        await delay(2000);
      } catch (error) {
        console.error('Error sending notification:', error);
      } finally {
        isSending.value = false;
        notificationSent.value = false;
        location.reload();
      }
    };

    return {
      tokensLeft,
      notificationType,
      from,
      title,
      tokenCost,
      sendNotification,
      isSending,
      notificationSent
    };
  }
};
</script>


<style scoped>
.notification-container {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
}

.token-header {
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.token-header p {
  font-size: 16px;
}

.token-header h2 {
  font-size: 32px;
  color: #333;
  margin: 5px 0;
}

.get-more-button {
  background-color: #4C6B30;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.content-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 6px;
  font-weight: bold;
}

select,
input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

input[readonly] {
  background-color: #f8f8f8;
}

#messageEditor {
  height: 150px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.send-button,
.cancel-button {
  width: 48%;
  padding: 10px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.cancel-button {
  background-color: #E0E0E0;
  color: black;
}

.send-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #6E9F49;
  outline: none;
  box-shadow: 0 0 5px rgba(110, 159, 73, 0.5);
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #4C6B30;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.success-message {
  color: #4C6B30;
  font-weight: bold;
  font-size: 18px;
}

@media(max-width: 1024px){
  .notification-container{
    margin-top: 100px;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .notification-container {
    padding: 15px;
  }

  .button-group {
    flex-direction: column;
    gap: 10px;
  }

  .send-button,
  .cancel-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .notification-container {
    width: 90%;
  }
}
</style>
