<template>
  <div class="dashboard-page">
    <div class="dashboard-content">
      <h1>Edit Coupon</h1>
      <div class="steps-container">
        <StepIndicator :currentStep="currentStep" />
        <component
          ref="currentStepComponent"
          :is="currentStepComponent"
          @nextStep="nextStep"
          @formValidation="updateFormValidation"
          :currentStep="currentStep"
        />
        <div class="navigation-buttons">
          <button @click="prevStep" :disabled="currentStep === 0">Previous</button>
          <button @click="cancel">Cancel</button>
          <button @click="handleNextStep" :disabled="!isFormValid">{{ currentStep === steps.length - 1 ? 'Update' : 'Next' }}</button>
        </div>
      </div>
      <div v-if="showPublishConfirm" class="publish-confirmation">
        <p>Are you sure you want to save?</p>
        <button @click="save">Yes</button>
        <button @click="cancelSave">No</button>
      </div>
      <div v-if="isLoading" class="loading-overlay">
        <div class="loader"></div>
        <p>Saving, do not refresh or close this page...</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import StepIndicator from '../../components/Dashboard/edit coupon/StepIndicator.vue';
import StepDetails from '../../components/Dashboard/edit coupon/StepDetails.vue';
import StepImages from '../../components/Dashboard/edit coupon/StepImages.vue';
import StepPreview from '../../components/Dashboard/edit coupon/StepPreview.vue';

export default {
  name: 'EditCoupon',
  components: {
    StepIndicator,
    StepDetails,
    StepImages,
    StepPreview,
  },
  data() {
    return {
      currentStep: 0,
      steps: ['StepDetails', 'StepImages', 'StepPreview'],
      isFormValid: false,
      showPublishConfirm: false,
      couponId: null,
      token: localStorage.getItem('token'),
      isLoading: false,
    };
  },
  computed: {
    currentStepComponent() {
      return this.steps[this.currentStep];
    },
  },
  methods: {
    updateFormValidation(isValid) {
      this.isFormValid = isValid;
    },
    async handleNextStep() {
      const currentComponent = this.$refs.currentStepComponent;

      if (this.isFormValid) {
        if (this.currentStep === 0 && currentComponent.validateAndSave) {
          currentComponent.validateAndSave();
        } else if (this.currentStep === 1 && currentComponent.saveImages) {
          currentComponent.saveImages();
          this.nextStep();
        } else if (this.currentStep === this.steps.length - 1) {
          this.showPublishConfirm = true;
        } else {
          this.nextStep();
        }
      }
    },
    nextStep() {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
        localStorage.setItem('currentStep', this.currentStep);
      }
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
        localStorage.setItem('currentStep', this.currentStep);
      }
    },
    async save() {
      this.isLoading = true;
      this.showPublishConfirm = false;

      const dealDetails = JSON.parse(localStorage.getItem('editDetails')) || {};
      const uploadedImages = JSON.parse(localStorage.getItem('uploadedImages')) || [];

      console.log(dealDetails);

      const businessId = JSON.parse(localStorage.getItem('user')).business_id;
      const formData = new FormData();
      formData.append('dealdetails', dealDetails.dealdetails || 'None');
      formData.append('dealtitle', dealDetails.dealtitle || 'None');
      formData.append('dealfineprint', dealDetails.dealfineprint || 'None');
      formData.append('expirationdate', dealDetails.expirationdate || 'None');
      formData.append('couponcode', dealDetails.couponcode || 'None');
      formData.append('istrending', 0);
      formData.append('ispublished', 1);
      formData.append('business_id', businessId);

      const existingImages = [];
      uploadedImages.forEach((image) => {
        if (typeof image.url === 'string' && image.url.startsWith('http')) {
          existingImages.push(image.url);
        } else if (image.url) {
          const blob = this.dataURLtoBlob(image.url);
          formData.append('coupon_image', blob, image.name);
        }
      });

      formData.append('existing_images', JSON.stringify(existingImages));

      console.log(formData);

      try {
        const response = await axios.put(`${process.env.VUE_APP_API_URL}/api/create-coupon/update/${this.couponId}`, formData, {
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('API response:', response.data);

        localStorage.removeItem('editDetails');
        localStorage.removeItem('dealDetails');
        localStorage.removeItem('uploadedImages');
        localStorage.removeItem('dataFetched');
        localStorage.removeItem('lastGeneratedCouponHash');
        localStorage.setItem('currentStep', 0);

        // Reload the page after saving
        this.$router.push('/dashboard/view-coupons');
      } catch (error) {
        console.error('Error saving coupon:', error);
      } finally {
        this.isLoading = false;
      }
    },
    cancelSave() {
      this.showPublishConfirm = false;
    },
    dataURLtoBlob(dataURL) {
      if (!dataURL) return null;
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    cancel() {
      localStorage.removeItem('editDetails');
      localStorage.removeItem('uploadedImages');
      localStorage.removeItem('dealDetails');
      localStorage.removeItem('dataFetched');
      localStorage.removeItem('lastGeneratedCouponHash');
      localStorage.setItem('currentStep', 0);
      this.currentStep = 0;
      this.$router.push('/dashboard/view-coupons');
    },
    async fetchCoupon() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/coupons/${this.couponId}`);
        const data = response.data;
        localStorage.setItem('editDetails', JSON.stringify(data));
        localStorage.setItem('uploadedImages', JSON.stringify(data.image_links));
        if(localStorage.getItem('dataFetched')){
          localStorage.setItem('dataFetched', 'false');
          location.reload();
        }
      } catch (error) {
        console.error('Error fetching coupon:', error);
      }
    },
  },
  mounted() {
    const savedStep = localStorage.getItem('currentStep');
    if (savedStep !== null) {
      this.currentStep = parseInt(savedStep, 10);
    }
    this.couponId = this.$route.params.id;

    // Check if data has already been fetched
    const dataFetched = localStorage.getItem('dataFetched');
    if (!dataFetched) {
      // If not, fetch the data and set the flag
      this.fetchCoupon();
      localStorage.setItem('dataFetched', 'true');
    }
  },
};
</script>

<style scoped>
.dashboard-content {
  margin-left: 300px;
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  height: 100vh;
  box-sizing: border-box;
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #6E9F49;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.publish-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.publish-confirmation p {
  margin-bottom: 10px;
}

.publish-confirmation button {
  margin-right: 10px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #4C6B30;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-overlay p {
  color: white;
  margin-top: 20px;
  font-size: 18px;
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .dashboard-content {
    margin-left: 0px;
    margin-top: 90px;
    padding: 15px;
  }

  .navigation-buttons {
    flex-direction: column;
    align-items: center;
  }

  button {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    margin-left: 100px;
    padding: 10px;
  }

  .navigation-buttons {
    flex-direction: column;
    align-items: center;
  }

  button {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .dashboard-content {
    margin-left: 0;
    padding: 5px;
    margin-top: 50px;
  }

  .navigation-buttons {
    flex-direction: column;
    align-items: center;
  }

  button {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>