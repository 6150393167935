<template>
  <div class="dashboard-page">
    <div class="dashboard-content">
      <h1>Create Coupon</h1>
      <div class="steps-container">
        <StepIndicator :currentStep="currentStep" />
        <component
          ref="currentStepComponent"
          :is="currentStepComponent"
          @nextStep="nextStep"
          @formValidation="updateFormValidation"
          :currentStep="currentStep"
        />
        <div class="navigation-buttons">
          <button @click="prevStep" :disabled="currentStep === 0">Previous</button>
          <button @click="cancel">Cancel</button>
          <button @click="handleNextStep" :disabled="!isFormValid">{{ currentStep === steps.length - 1 ? 'Publish' : 'Next' }}</button>
        </div>
      </div>
      <div v-if="showPublishConfirm" class="publish-confirmation">
        <p>Are you sure you want to publish?</p>
        <button @click="publish">Yes</button>
        <button @click="cancelPublish">No</button>
      </div>
    </div>
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-message">
        <div class="loader"></div>
        <p>{{ loadingMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import StepIndicator from '../../components/Dashboard/create coupon/StepIndicator.vue';
import StepDetails from '../../components/Dashboard/create coupon/StepDetails.vue';
import StepImages from '../../components/Dashboard/create coupon/StepImages.vue';
import StepPreview from '../../components/Dashboard/create coupon/StepPreview.vue';

export default {
  name: 'CreateCoupon',
  components: {
    StepIndicator,
    StepDetails,
    StepImages,
    StepPreview,
  },
  data() {
    return {
      currentStep: 0,
      steps: ['StepDetails', 'StepImages', 'StepPreview'],
      isFormValid: false,
      showPublishConfirm: false,
      token: localStorage.getItem('token'),
      isLoading: false,
      loadingMessage: '',
    };
  },
  computed: {
    currentStepComponent() {
      return this.steps[this.currentStep];
    },
  },
  methods: {
    updateFormValidation(isValid) {
      this.isFormValid = isValid;
    },
    async handleNextStep() {
      const currentComponent = this.$refs.currentStepComponent;

      if (this.isFormValid) {
        if (this.currentStep === 0 && currentComponent.validateAndSave) {
          currentComponent.validateAndSave();
        } else if (this.currentStep === 1 && currentComponent.saveImages) {
          currentComponent.saveImages();
          this.nextStep();
        } else if (this.currentStep === this.steps.length - 1) {
          this.showPublishConfirm = true;
        } else {
          this.nextStep();
        }
      }
    },
    nextStep() {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
        localStorage.setItem('currentStep', this.currentStep);
      }
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
        localStorage.setItem('currentStep', this.currentStep);
      }
    },
    async publish() {
      this.isLoading = true;
      this.loadingMessage = 'Saving... Do not exit or refresh this screen.';

      const dealDetails = JSON.parse(localStorage.getItem('dealDetails')) || {};
      const uploadedImages = JSON.parse(localStorage.getItem('uploadedImages')) || [];

      console.log('dealDetails:', dealDetails);  // Debug: Verify dealDetails content

      const businessId = JSON.parse(localStorage.getItem('user')).business_id;
      const formData = new FormData();
      
      // Category is now fetched from the backend based on business_id, so no need to send it here.
      formData.append('dealDetails', dealDetails.dealDetails || 'None');
      formData.append('dealTitle', dealDetails.dealTitle || 'None');
      formData.append('dealFinePrint', dealDetails.dealFinePrint || 'None');
      formData.append('istrending', 0);
      formData.append('ispublished', 1);
      formData.append('business_id', businessId);

      // Correct keys for couponcode and expirationdate
      formData.append('expirationdate', dealDetails.expirationDate || 'None');  // lowercase 'expirationdate'
      formData.append('couponcode', dealDetails.couponCode || 'None');  // lowercase 'couponcode'

      // Handle images
      uploadedImages.forEach((image) => {
        const blob = this.dataURLtoBlob(image.url);
        formData.append('coupon_image', blob, image.name);
      });

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/create-coupon`, formData, {
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.status === 200) {
          this.loadingMessage = 'Coupon Published';
          setTimeout(() => {
            localStorage.removeItem('dealDetails');
            localStorage.removeItem('uploadedImages');
            localStorage.removeItem('currentBusiness');
            localStorage.removeItem('generatedCouponImage');
            localStorage.setItem('currentStep', 0);
            localStorage.removeItem('lastGeneratedCouponHash');
            localStorage.removeItem('generatedCouponImage');
            this.$router.push('/dashboard/view-coupons');
          }, 5000);
        } else {
          this.loadingMessage = 'Failed to save. Please try again.';
          setTimeout(() => {
            this.isLoading = false;
          }, 2000);
        }
      } catch (error) {
        console.error('Error publishing coupon:', error);
        this.loadingMessage = 'Failed to save. Please try again.';
        setTimeout(() => {
          this.isLoading = false;
        }, 2000);
      }
    },
    cancelPublish() {
      this.showPublishConfirm = false;
    },
    dataURLtoBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    cancel() {
      localStorage.removeItem('dealDetails');
      localStorage.removeItem('uploadedImages');
      localStorage.removeItem('currentBusiness');
      localStorage.removeItem('generatedCouponImage');
      localStorage.setItem('currentStep', 0);
      localStorage.removeItem('lastGeneratedCouponHash');
      localStorage.removeItem('generatedCouponImage');
      this.currentStep = 0;
      location.reload();
    },
  },
  mounted() {
    const savedStep = localStorage.getItem('currentStep');
    if (savedStep !== null) {
      this.currentStep = parseInt(savedStep, 10);
    }
  },
};
</script>

<style scoped>
.dashboard-content {
  margin-left: 300px; /* Default margin for sidebar */
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  height: 100vh;
  box-sizing: border-box;
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  width: 100%;
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #6E9F49;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.publish-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.publish-confirmation p {
  margin-bottom: 10px;
}

.publish-confirmation button {
  margin-right: 10px;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.loading-message {
  text-align: center;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #4C6B30;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Media Queries for responsiveness */
@media (max-width: 1024px) {
  .dashboard-content {
    margin-left: 0px; /* Adjust margin for smaller screens */
    margin-top: 90px;
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    margin-left: 200px; /* Adjust margin for smaller screens */
    padding: 15px; /* Adjust padding for smaller screens */
  }

  .navigation-buttons {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons */
  }

  button {
    padding: 8px 16px; /* Adjust button padding for smaller screens */
    margin-bottom: 10px; /* Add margin between buttons */
  }
}

@media (max-width: 480px) {
  .dashboard-content {
    margin-left: 0; /* Remove margin for very small screens */
    padding: 10px; /* Adjust padding for very small screens */
  }

  .step-preview{
    margin-top: 0;
  }

  .navigation-buttons {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center buttons */
  }

  button {
    padding: 6px 12px; /* Adjust button padding for very small screens */
    margin-bottom: 10px; /* Add margin between buttons */
  }

  .publish-confirmation {
    width: 90%; /* Adjust width for very small screens */
    padding: 15px; /* Adjust padding for very small screens */
  }
}
</style>
