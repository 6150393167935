<template>
  <div class="category-filter">
    <button
      v-for="category in categories"
      :key="category.id"
      @click="selectCategory(category)"
      :class="{ active: activeCategory === category.id }"
    >
      <i :class="getCategoryIcon(category.id)"></i>
      <span>{{ category.name }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'CategoryFilter',
  props: {
    categories: Array,
    defaultCategory: String
  },
  data() {
    return {
      activeCategory: this.defaultCategory,
    };
  },
  mounted() {
    this.$emit('category-selected', { id: this.defaultCategory });
  },
  methods: {
    selectCategory(category) {
      this.activeCategory = category.id;
      this.$emit('category-selected', category);
    },
    getCategoryIcon(categoryId) {
      const iconMap = {
        'all': 'fas fa-th-large',
        'Automotive': 'fas fa-car',
        'Restaurants': 'fas fa-utensils',
        'Things-to-do': 'fas fa-ticket-alt',
        'Health-fitness': 'fas fa-heartbeat',
        'Retail': 'fas fa-shopping-bag',
        'Beauty-spa': 'fas fa-spa',
        'Home-services': 'fas fa-home',
        'Technology': 'fas fa-laptop',
        'Other': 'fas fa-ellipsis-h'
      };
      return iconMap[categoryId] || 'fas fa-tag';
    }
  },
};
</script>

<style scoped>
.category-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;
  padding: 1rem;
}

.category-filter button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0.5rem;
}

.category-filter button i {
  font-size: 1.5rem;
  margin-bottom: 0.3rem;
  color: #000; /* Set icon color to black */
}

.category-filter button span {
  font-size: 0.7rem;
  text-align: center;
  line-height: 1;
  color: #000; /* Set text color to black */
}

.category-filter button.active {
  background-color: #f0f0f0;
  border-color: #999;
}

.category-filter button:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .category-filter {
    justify-content: flex-start;
  }

  .category-filter button {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 480px) {

  .category-filter{
    padding-bottom: 0%;
    margin-bottom: -75px;
  }
  

  .category-filter button {
    width: 60px;
    height: 60px;
  }

  .category-filter button i {
    font-size: 1.2rem;
  }

  .category-filter button span {
    font-size: 0.6rem;
  }
}
</style>