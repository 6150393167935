<template>
  <div class="checkout-page">
    <h1>Checkout</h1>
    <div class="checkout-container">
      <div class="billing-details">
        <h2>Billing Details</h2>
        <form @input="saveBillingDetails">
          <div class="form-row">
            <div class="form-group">
              <label for="firstName">*First Name</label>
              <input type="text" id="firstName" v-model="billingDetails.firstName" required />
            </div>
            <div class="form-group">
              <label for="lastName">*Last Name</label>
              <input type="text" id="lastName" v-model="billingDetails.lastName" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="companyName">Company Name</label>
              <input type="text" id="companyName" v-model="billingDetails.companyName" />
            </div>
            <div class="form-group">
              <label for="address">*Address</label>
              <input type="text" id="address" v-model="billingDetails.address" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="city">*Town/City</label>
              <input type="text" id="city" v-model="billingDetails.city" required />
            </div>
            <div class="form-group">
              <label for="zipCode">*Zip Code</label>
              <input type="text" id="zipCode" v-model="billingDetails.zipCode" required />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="country">Country</label>
              <select id="country" v-model="billingDetails.country" required>
                <option v-for="country in countries" :key="country.code" :value="country.code">
                  {{ country.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="email">*Email Address</label>
              <input type="email" id="email" v-model="billingDetails.email" required />
            </div>
            <div class="form-group">
              <label for="phone">*Phone</label>
              <input type="tel" id="phone" v-model="billingDetails.phone" required />
            </div>
          </div>
        </form>
      </div>
      <div class="order-summary">
        <h2>Your Order</h2>
        <div v-for="(item, index) in checkoutCartItems" :key="index" class="order-item">
          <div class="item-name">{{ item.name }}</div>
          <div class="item-price">${{ calculateDiscountedPrice(item.originalPrice, item.discount) }}</div>
        </div>
        <div class="subtotal-cost">
          <span>Subtotal</span>
          <span>${{ subtotalCost }}</span>
        </div>
        <div class="tax-cost">
          <span>Tax (6.5%)</span>
          <span>${{ taxCost }}</span>
        </div>
        <div class="coupon-code">
          <label for="couponCode">Coupon Code</label>
          <input type="text" v-model="couponCode" placeholder="Enter coupon code" />
          <button @click="applyCoupon" :disabled="isProcessing">Apply Coupon</button>
          <div v-if="couponMessage">{{ couponMessage }}</div>
        </div>
        
        <div class="discount-amount" v-if="discountAmount > 0">
          <span>Discount</span>
          <span>-${{ discountAmount }}</span>
        </div>
        <div class="total-cost">
          <span>Total Cost</span>
          <span>${{ totalCost }}</span>
        </div>
        <h2>Payment Information</h2>
        <form @submit.prevent="handleCheckout">
          <label for="card-element">Credit or debit card</label>
          <div id="card-element">
            <!-- A Stripe Element will be inserted here. -->
          </div>
          <!-- Used to display form errors. -->
          <div id="card-errors" role="alert"></div>
          <button type="submit" class="place-order-button" :disabled="isProcessing">Place Order</button>
          <div v-if="isProcessing" class="processing-spinner">
            <div class="spinner"></div>
            <span>Processing...</span>
          </div>
        </form>
        <div v-if="transactionMessage" class="transaction-message">{{ transactionMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

export default {
  name: 'CheckoutPage',
  data() {
    return {
      stripe: null,
      elements: null,
      card: null,
      transactionMessage: '',
      isProcessing: false,
      billingDetails: {
        firstName: '',
        lastName: '',
        companyName: '',
        address: '',
        city: '',
        zipCode: '',
        country: 'US', // Default to United States
        email: '',
        phone: ''
      },
      checkoutCartItems: JSON.parse(localStorage.getItem('checkoutCartItems')) || [],
      countries: [] // Initialize as empty array
    };
  },
  computed: {
    subtotalCost() {
      try {
        const subtotal = this.checkoutCartItems.reduce((total, item) => {
          const originalPrice = parseFloat(item.originalPrice) || 0;
          const discount = parseFloat(item.discount) || 0;
          const quantity = parseInt(item.quantity) || 1;
          
          const discountedPrice = this.calculateDiscountedPrice(originalPrice, discount);
          return total + (discountedPrice * quantity);
        }, 0);
        
        return Number.isFinite(subtotal) ? subtotal.toFixed(2) : '0.00';
      } catch (error) {
        console.error('Error calculating subtotal:', error);
        return '0.00';
      }
    },
    taxCost() {
      try {
        const subtotal = parseFloat(this.subtotalCost) || 0;
        const discount = parseFloat(this.discountAmount) || 0;
        const taxableAmount = Math.max(0, subtotal - discount);
        const tax = taxableAmount * 0.065;
        
        return Number.isFinite(tax) ? tax.toFixed(2) : '0.00';
      } catch (error) {
        console.error('Error calculating tax:', error);
        return '0.00';
      }
    },
    totalCost() {
      try {
        const subtotal = parseFloat(this.subtotalCost) || 0;
        const tax = parseFloat(this.taxCost) || 0;
        const discount = parseFloat(this.discountAmount) || 0;
        const total = Math.max(0, subtotal + tax - discount);
        
        return Number.isFinite(total) ? total.toFixed(2) : '0.00';
      } catch (error) {
        console.error('Error calculating total:', error);
        return '0.00';
      }
    }
  },
  mounted() {
    this.initializeStripe();
    this.loadBillingDetails();
    this.fetchCountries(); // Fetch countries when component is mounted
    window.addEventListener('cart-updated', this.updateCartItems);
  },
  beforeUnmount() {
    window.removeEventListener('cart-updated', this.updateCartItems);
  },
  methods: {
    async initializeStripe() {
      this.stripe = await loadStripe('pk_test_51HVf6pEJDnrWZ4dJfAF33kjVRek3e066N5uQjkVdFHqeFvWe6AQgui21oRY00QBWgtVTkoOb6L0nD3BbTcGVYnRU00wYjEIyX4');
      this.elements = this.stripe.elements();
      this.card = this.elements.create('card');
      this.card.mount('#card-element');
    },
    updateCheckoutCart(items) {
      this.checkoutCartItems = items || [];
      localStorage.setItem('checkoutCartItems', JSON.stringify(this.checkoutCartItems));
    },
    removeFromCheckoutCart(giftCardId, name) {
      const user = JSON.parse(localStorage.getItem('user'));

      if (!user || !user.id) {
        // If user is not logged in, remove the item from localStorage directly
        this.checkoutCartItems = this.checkoutCartItems.filter(
          (item) => item.giftCardId !== giftCardId || item.name !== name
        );

        this.updateCheckoutCart(this.checkoutCartItems);

        const event = new CustomEvent('update-checkout-cart', { detail: this.checkoutCartItems });
        window.dispatchEvent(event);
        return;
      }

      // If the user is logged in, send a request to the API to remove the item
      const token = localStorage.getItem('token');
      axios
        .put(
          `${process.env.VUE_APP_API_URL}/api/shoppingCart/remove-from-cart/${user.id}`,
          { giftCardId, name },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          this.checkoutCartItems = this.checkoutCartItems.filter(
            (item) => item.giftCardId !== giftCardId || item.name !== name
          );

          this.updateCheckoutCart(this.checkoutCartItems);

          const event = new CustomEvent('update-checkout-cart', { detail: this.checkoutCartItems });
          window.dispatchEvent(event);
        })
        .catch((error) => {
          console.error('Error removing from checkout cart:', error);
        });
    },
    async fetchCountries() {
      try {
        const response = await axios.get('https://restcountries.com/v2/all');
        this.countries = response.data.map(country => ({
          code: country.alpha2Code,
          name: country.name
        }));
        this.billingDetails.country = 'US'; // Set default country to United States
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    },
    calculateDiscountedPrice(originalPrice, discount) {
      try {
        const price = parseFloat(originalPrice) || 0;
        const discountPercent = parseFloat(discount) || 0;
        const discountedPrice = price * (1 - discountPercent / 100);
        
        return Number.isFinite(discountedPrice) ? discountedPrice : 0;
      } catch (error) {
        console.error('Error calculating discounted price:', error);
        return 0;
      }
    },
    saveBillingDetails() {
      localStorage.setItem('billingDetails', JSON.stringify(this.billingDetails));
    },
    loadBillingDetails() {
      try {
        const savedDetails = localStorage.getItem('billingDetails');
        if (savedDetails) {
          this.billingDetails = JSON.parse(savedDetails);
        } else {
          console.warn('No billing details found in localStorage');
          this.billingDetails = {
            firstName: '',
            lastName: '',
            companyName: '',
            address: '',
            city: '',
            zipCode: '',
            country: 'US',
            email: '',
            phone: ''
          };
        }
      } catch (error) {
        console.error('Error parsing billing details:', error);
      }
    },
    async applyCoupon() {
      if (!this.couponCode) {
        this.couponMessage = 'Please enter a valid coupon code.';
        return;
      }

      this.isProcessing = true;
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/apply-coupon`, {
          code: this.couponCode,
          subtotal: parseFloat(this.subtotalCost) || 0
        });

        if (response.data.valid) {
          this.discountAmount = parseFloat(response.data.discountAmount) || 0;
          this.couponMessage = 'Coupon applied successfully!';
        } else {
          this.couponMessage = 'Invalid coupon code.';
          this.discountAmount = 0;
        }
      } catch (error) {
        this.couponMessage = 'Invalid coupon code.';
        this.discountAmount = 0;
      } finally {
        this.isProcessing = false;
      }
    },
    updateCartItems(event) {
      if (event && event.detail) {
        this.checkoutCartItems = event.detail;
      } else {
        this.checkoutCartItems = JSON.parse(localStorage.getItem('checkoutCartItems')) || [];
      }
    },
    async handleCheckout() {
      if (parseFloat(this.totalCost) <= 0) {
        this.transactionMessage = 'Invalid order total. Please check your cart.';
        return;
      }

      window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'begin_checkout',
          ecommerce: {
            currency: 'USD',
            value: parseFloat(this.totalCost),
            items: this.checkoutCartItems.map(item => ({
              item_name: item.name,
              item_id: item.giftCardId,
              price: parseFloat(item.originalPrice),
              quantity: parseInt(item.quantity)
            }))
          }
        });      

      this.isProcessing = true;
      try {
        const userId = JSON.parse(localStorage.getItem('user'))?.id;
        if (!userId) {
          throw new Error('User not logged in');
        }

        const grandTotal = parseFloat(this.totalCost) || 0;
        if (grandTotal <= 0) {
          throw new Error('Invalid order total');
        }

        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/payment/create-payment-intent`, {
          amount: Math.round(grandTotal * 100),
          currency: 'usd',
          items: this.checkoutCartItems,
          couponCode: this.couponCode,
          discountAmount: this.discountAmount
        });

        // Rest of the checkout logic remains the same...
        const result = await this.stripe.confirmCardPayment(response.data.clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: `${this.billingDetails.firstName} ${this.billingDetails.lastName}`,
              address: {
                line1: this.billingDetails.address,
                city: this.billingDetails.city,
                postal_code: this.billingDetails.zipCode,
                country: this.billingDetails.country,
              },
              email: this.billingDetails.email,
              phone: this.billingDetails.phone,
            },
          },
        });

        if (result.error) {
          this.transactionMessage = result.error.message;
          return;
        }

        const orderConfirmation = await axios.post(`${process.env.VUE_APP_API_URL}/api/payment/confirm-order`, {
          userId,
          items: this.checkoutCartItems.map(item => ({
            giftCardId: item.giftCardId,
            quantity: parseInt(item.quantity) || 1,
            dealTitle: item.dealTitle,
            discount: parseFloat(item.discount) || 0,
            originalPrice: parseFloat(item.originalPrice) || 0,
            businessId: item.businessId,
            name: item.name,
            optionId: item.optionId
          })),
          totalCost: grandTotal.toFixed(2),
          couponCode: this.couponCode,
          discountAmount: this.discountAmount,
          paymentIntentId: result.paymentIntent.id,
          email: this.billingDetails.email,
        });

        if (orderConfirmation.data.emailSent) {
          console.log('Order confirmation email sent successfully');
        }

        this.$router.push({
          name: 'OrderConfirmation',
          params: {
            userId: userId,
            orderId: orderConfirmation.data.order.orderId,
            orderData: JSON.stringify(orderConfirmation.data.order)
          }
        }).then(() => {
          this.checkoutCartItems.forEach((item) => {
            this.removeFromCheckoutCart(item.giftCardId, item.name);
          });
          window.scrollTo(0, 0);
        });

        localStorage.setItem('checkoutCartItems', '[]');
      } catch (error) {
        this.transactionMessage = 'An error occurred during checkout. Please try again.';
        console.error('Error during checkout:', error);
      } finally {
        this.isProcessing = false;
      }
    }
  }
};
</script>


<style scoped>
.checkout-page {
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  margin-bottom: 50px;
}

h1 {
  text-align: center;
  margin-bottom: 40px;
}

.checkout-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.order-summary, .billing-details {
  width: 48%;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.billing-details h2, .order-summary h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input, .form-group select {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.form-row .form-group {
  flex: 1;
}

.place-order-button {
  width: 100%;
  padding: 15px;
  background-color: #4C6B30;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px;
}

.order-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

.subtotal-cost, .tax-cost, .total-cost {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding: 10px 0;
}

.total-cost {
  border-top: 2px solid #333;
  font-size: 1.2em;
  margin-top: 20px;
}

#card-element {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.transaction-message {
  margin-top: 30px;
  color: green;
  font-size: 1.2em;
  font-weight: bold;
}

.processing-spinner {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.processing-spinner .spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top: 3px solid #4C6B30;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

.coupon-code {
  margin: 20px 0;
  padding: 20px;
  background: #f7f9fc;
  border: 1px solid #e1e7ef;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.coupon-code:focus-within {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.coupon-code label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  color: #374151;
}

.coupon-code-input-group {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.coupon-code input {
  flex: 1;
  padding: 12px 16px;
  border: 2px solid #e1e7ef;
  border-radius: 6px;
  font-size: 0.95em;
  transition: border-color 0.2s ease;
}

.coupon-code input:focus {
  outline: none;
  border-color: #4C6B30;
}

.coupon-code button {
  padding: 12px 24px;
  background-color: #4C6B30;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px;
  margin-left: 10px;
}

.coupon-code button:hover:not(:disabled) {
  background-color: #3d5626;
  transform: translateY(-1px);
}

.coupon-code button:disabled {
  background-color: #a8b8a8;
  cursor: not-allowed;
}

.coupon-message {
  margin-top: 8px;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 8px;
}

.coupon-message.success {
  background-color: #ecfdf5;
  color: #065f46;
  border: 1px solid #a7f3d0;
}

.coupon-message.error {
  background-color: #fef2f2;
  color: #991b1b;
  border: 1px solid #fecaca;
}

.coupon-message::before {
  content: '';
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
}

.coupon-message.success::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23065f46'%3E%3Cpath fill-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z' clip-rule='evenodd'/%3E%3C/svg%3E");
}

.coupon-message.error::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23991b1b'%3E%3Cpath fill-rule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z' clip-rule='evenodd'/%3E%3C/svg%3E");
}

.discount-amount {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  color: #059669;
  font-weight: 600;
  border-top: 1px dashed #e5e7eb;
  margin-top: 10px;
}

.discount-amount span:first-child::before {
  content: '🏷️';
  margin-right: 8px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .checkout-page{
    padding-top: 250px;
  }

  .checkout-container {
    flex-direction: column;
    align-items: center;
  }

  .order-summary, .billing-details {
    width: 100%;
    max-width: 600px;
  }

  .form-row {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .checkout-page {
    max-width: 100%;
    padding: 0 20px;
  }

  .checkout-container {
    padding: 0;
    flex-direction: column;
    align-items: center;
  }

  .form-group input, .form-group select, #card-element {
    padding: 8px;
  }

  .place-order-button {
    padding: 10px;
    font-size: 16px;
  }

  .coupon-code button {
    width: 100%;
  }
}

@media(max-width: 480px){
  .checkout-page{
    padding-top: 350px;
  }
  
}
</style>
