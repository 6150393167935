<template>
  <div class="step-preview">
    <!-- Deal Title and Business Info -->
    <div class="deal-header">
      <h2 class="deal-title">{{ giftCardDetails.dealTitle }}</h2>
      <div class="business-info">
      </div>
    </div>

    <div class="content-container">
      <!-- Left Side -->
      <div class="left-side">
        <!-- Image Viewer -->
        <div class="image-viewer">
          <button class="main-arrow left" @click="prevImage" :disabled="selectedImageIndex === 0">&#9664;</button>
          <div class="main-image">
            <img :src="currentImageUrl" alt="Main Image" />            
          </div>
          <button class="main-arrow right" @click="nextImage" :disabled="selectedImageIndex === images.length - 1">&#9654;</button>
        </div>

        <!-- Thumbnails -->
        <div class="thumbnail-slider-container">
          <button class="thumbnail-arrow left" v-if="images.length > thumbnailsPerPage" @click="prevBatch" :disabled="currentBatch === 0">
            <span>&#9664;</span>
          </button>
          <div class="thumbnail-slider">
            <div class="thumbnail-container">
                <div
                  class="image-preview"
                  v-for="(image, index) in images"
                  :key="index"
                  @click="selectImage(index)"
                  :class="{ 'selected': index === selectedImageIndex }"
                >
                  <img :src="image.url || require('@/assets/default-image.png')" :alt="'Image ' + (index + 1)" />
              </div>
              <div v-if="images.length === 0" class="image-preview">
                <img :src="require('@/assets/default-image.png')" alt="Default Thumbnail" />
              </div>
            </div>
          </div>
          <button class="thumbnail-arrow right" v-if="images.length > thumbnailsPerPage" @click="nextBatch" :disabled="(currentBatch + 1) * thumbnailsPerPage >= images.length">
            <span>&#9654;</span>
          </button>
        </div>

                <!-- Mobile Right Side - Shows at 1024px or less -->
                <div class="right-side-mobile">
          <h2 class="select-option">Select Option:</h2>
          <div class="options-container">
            <div v-for="(option, index) in options" :key="index" class="option-item">
              <div class="option-content">
                <input type="radio" :id="'option-mobile-' + index" name="option-mobile" :value="index" v-model="selectedOptionIndex" />
                <label :for="'option-mobile-' + index" class="option-label">
                  <div class="option-title">{{ option.dealName }}</div>
                  <div class="option-pricing">
                    <span class="original-price">${{ option.currentPrice.toFixed(2) }}</span>
                    <span class="new-price">${{ calculateCustomerPays(option) }}</span>
                    <span class="discount">{{ option.discount }}% Off</span>
                  </div>
                  <div class="option-details">
                    <p>In Stock: {{ option.numberInStock }}</p>
                    <p>Max Purchase Amount: {{ option.maxPerPurchase }}</p>
                  </div>
                </label>
              </div>
              <div class="purchase-controls">
                <div class="quantity-control">
                  <button 
                    @click="decrementQuantity(option.id)" 
                    :disabled="!purchaseQuantities[index] || purchaseQuantities[index] <= 1"
                  >-</button>
                  <input 
                    type="number" 
                    v-model.number="purchaseQuantities[index]" 
                    min="1"
                    :max="option.maxPerPurchase"
                  />
                  <button 
                    @click="incrementQuantity(option.id)" 
                    :disabled="purchaseQuantities[index] >= option.maxPerPurchase"
                  >+</button>
                </div>
                <button class="add-to-cart" @click="addToCart(option)">
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Nav Links for About, Fine Print, Reviews -->
        <div class="section-nav">
          <a href="#about">About</a>
          <a href="#fine-print">Fine Print</a>
          <a href="#reviews">Reviews</a>
        </div>
        <hr class="text-separator" />

        <!-- About Deal -->
        <div id="about" class="about-deal" v-if="giftCardDetails.dealDetails">
          <h2>About This Coupon</h2>
          <div v-html="giftCardDetails.dealDetails"></div>
        </div>

        <!-- Fine Print -->
        <div id="fine-print" class="fine-print" v-if="giftCardDetails.dealFinePrint">
          <h2>Fine Print</h2>
          <hr class="text-separator" />
          <div v-html="giftCardDetails.dealFinePrint"></div>
        </div>
      </div>

      <!-- Right Side -->
      <div class="right-side">
        <h2 class="select-option">Select Option:</h2>
        <div class="options-container">
          <div v-for="(option, index) in options" :key="index" class="option-item">
            <div class="option-content">
              <input type="radio" :id="'option-' + index" name="option" :value="index" v-model="selectedOptionIndex" />
              <label :for="'option-' + index" class="option-label">
                <div class="option-title">{{ option.dealName }}</div>
                <div class="option-pricing">
                  <span class="original-price">${{ option.currentPrice.toFixed(2) }}</span>
                  <span class="new-price">${{ calculateCustomerPays(option) }}</span>
                  <span class="discount">{{ option.discount }}% Off</span>
                </div>
                <div class="option-details">
                  <p>In Stock: {{ option.numberInStock }}</p>
                  <p>Max Purchase Amount: {{ option.maxPerPurchase }}</p>
                </div>
              </label>
            </div>
            <div class="purchase-controls">
              <div class="quantity-control">
                <button 
                  @click="decrementQuantity(option.id)" 
                  :disabled="!purchaseQuantities[index] || purchaseQuantities[index] <= 1"
                >-</button>
                <input 
                  type="number" 
                  v-model.number="purchaseQuantities[index]" 
                  min="1"
                  :max="option.maxPerPurchase"
                />
                <button 
                  @click="incrementQuantity(option.id)" 
                  :disabled="purchaseQuantities[index] >= option.maxPerPurchase"
                >+</button>
              </div>
              <button class="add-to-cart" @click="addToCart(option)">
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="showConfirmation" class="modal-overlay" @click="closeModal">
      <div class="modal" @click.stop>
        <h3>Confirm Purchase</h3>
        <p>Are you sure you want to proceed with this purchase?</p>
        <div class="modal-buttons">
          <button @click="confirmPurchase" class="submit-button">Confirm</button>
          <button @click="closeModal" class="cancel-button">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepPreview',
  data() {
    return {
      giftCardDetails: {
        dealTitle: '',
        category: '',
        dealDetails: '',
        dealFinePrint: '',
      },
      images: [],
      selectedImageIndex: 0,
      currentBatch: 0,
      thumbnailsPerPage: 6,
      options: [],
      selectedOptionIndex: 0,
      purchaseQuantities: [],
      showConfirmation: false,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    visibleThumbnails() {
      if (!this.images) return [];
      const start = this.currentBatch * this.thumbnailsPerPage;
      const end = start + this.thumbnailsPerPage;
      return this.images.slice(start, end);
    },
    currentImageUrl() {
      // Check if images exist and are not empty, then return the selected image URL
      return this.images && this.images.length > 0 && this.images[this.selectedImageIndex]
        ? this.images[this.selectedImageIndex].url
        : require('@/assets/default-image.png'); // Fallback to default image
    },
  },
  methods: {
    prevImage() {
      if (this.selectedImageIndex > 0) {
        this.selectedImageIndex--;
      }
    },
    nextImage() {
      if (this.selectedImageIndex < this.images.length - 1) {
        this.selectedImageIndex++;
      }
    },
    prevBatch() {
      if (this.currentBatch > 0) {
        this.currentBatch--;
      }
    },
    nextBatch() {
      if ((this.currentBatch + 1) * this.thumbnailsPerPage < this.images.length) {
        this.currentBatch++;
      }
    },
    selectImage(index) {
      this.selectedImageIndex = index;
    },
    calculateCustomerPays(option) {
      return (option.currentPrice * (1 - option.discount / 100)).toFixed(2);
    },
    loadLocalStorageData() {
      const savedDetails = JSON.parse(localStorage.getItem('giftCardDetails'));
      if (savedDetails) {
        this.giftCardDetails = savedDetails;
      }

      const savedImages = JSON.parse(localStorage.getItem('uploadedImagesGC'));
      if (savedImages) {
        this.images = savedImages;
        this.selectedImageIndex = 0;
      } else {
        this.images = [];
      }

      const savedOptions = JSON.parse(localStorage.getItem('option'));
      if (savedOptions) {
        const optionCount = savedOptions.dealName.length; // Get the number of options
        this.options = [];

        for (let i = 0; i < optionCount; i++) {
          this.options.push({
            dealName: savedOptions.dealName[i] || '',
            currentPrice: savedOptions.currentPrice[i] || 0,
            numberInStock: savedOptions.numberInStock[i] || 0,
            discount: savedOptions.discount[i] || 0,
            maxPerPurchase: savedOptions.maxPerPurchase[i] || 0,
            businessCut: savedOptions.businessCut[i] || 0,
            releaseAmount: savedOptions.releaseAmount[i] || 0,
            releaseFrequency: savedOptions.releaseFrequency[i] || 'none',
            exactDate: savedOptions.exactDate[i] || '',
          });
        }
        this.purchaseQuantities = new Array(this.options.length).fill(1); // Initialize purchase quantities
      } else {
        // Initialize options as an empty array if no data is found
        this.options = [];
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.updateThumbnailsPerPage();
    },
    updateThumbnailsPerPage() {
      if (this.windowWidth <= 480) {
        this.thumbnailsPerPage = 3;
      } else if (this.windowWidth <= 768) {
        this.thumbnailsPerPage = 4;
      } else {
        this.thumbnailsPerPage = 6;
      }
    },
    buyNow() {
      // Implement buy now functionality
      console.log('Buy Now clicked');
    },
    addToCart() {
      // Implement add to cart functionality
      console.log('Add to Cart clicked');
    },
  },
  mounted() {
    this.loadLocalStorageData();
    this.updateThumbnailsPerPage();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped>
.step-preview {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.deal-header {
  margin-bottom: 30px;
  max-width: 700px;
}

.deal-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.business-info {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.content-container {
  display: flex;
  gap: 40px;
}

.left-side {
  flex: 1;
}

.right-side {
  width: 500px;
  position: sticky;
  top: 20px;
  align-self: flex-start;
}

.image-viewer {
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-image {
  width: 100%;
  height: 100%;
}

.main-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.main-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.main-arrow.left { left: 10px; }
.main-arrow.right { right: 10px; }

.thumbnail-slider-container {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  overflow-x: auto;
  padding-bottom: 10px;
}

.thumbnail-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.image-preview {
  flex: 0 0 80px;
  width: 80px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s;
}

.image-preview.selected {
  border-color: #4c6b30;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section-nav {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.section-nav a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  padding: 5px 0;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s;
}

.section-nav a:hover,
.section-nav a:focus {
  border-bottom-color: #4c6b30;
}

.about-deal, .fine-print, .customer-reviews {
  margin-top: 30px;
}

.about-deal h2, .fine-print h2, .customer-reviews h2 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
}

.text-separator {
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 20px 0;
}

.right-side h2 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #333;
}

.options-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.option-item {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  transition: box-shadow 0.3s;
}

.option-item:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.option-content {
  display: flex;
  align-items: flex-start;
}

.option-label {
  margin-left: 10px;
  flex: 1;
}

.option-title {
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.option-pricing {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}

.original-price {
  color: #999;
  text-decoration: line-through;
  font-size: 14px;
}

.new-price {
  font-size: 18px;
  font-weight: bold;
  color: #4c6b30;
}

.discount {
  background-color: #4c6b30;
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.option-details {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.purchase-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.quantity-control {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.quantity-control button {
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.quantity-control button:hover:not(:disabled) {
  background-color: #e0e0e0;
}

.quantity-control button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.quantity-control input {
  width: 40px;
  text-align: center;
  border: none;
  font-size: 16px;
  -moz-appearance: textfield;
}

.quantity-control input::-webkit-outer-spin-button,
.quantity-control input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.buy-now,
.add-to-cart,
.become-fan {
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 70%;
}

.buy-now {
  background-color: #4c6b30;
  color: white;
}

.buy-now:hover {
  background-color: #45a049;
}

.add-to-cart {
  background-color: #6e9f49;
  color: white;
}

.add-to-cart:hover {
  background-color: #5c8a3c;
}

.remove-from-cart {
  background-color: #dc3545;
}

.remove-from-cart:hover {
  background-color: #c82333;
}

.become-fan {
  background-color: #8db36d;
  color: white;
}

.become-fan:hover {
  background-color: #7a9e5c;
}

.share-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
}

.share-section h3 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icon {
  font-size: 24px;
  color: #4c6b30;
  text-decoration: none;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #6e9f49;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
}

.toggle-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.toggle-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4c6b30;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.submit-button,
.unsubscribe-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  flex: 1;
}

.submit-button {
  background-color: #4c6b30;
  color: white;
}

.submit-button:hover {
  background-color: #6e9f49;
}

.unsubscribe-button {
  background-color: #dc3545;
  color: white;
}

.unsubscribe-button:hover {
  background-color: #c82333;
}

.right-side-mobile {
  display: none; /* Hidden by default */
  width: 100%;
  margin: 30px 0;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .content-container {
    flex-direction: column;
  }

  .right-side {
    display: none; /* Hide desktop version */
  }
  .left-side {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .right-side-mobile {
    display: block;
    order: 2; /* Place after thumbnails but before nav */
  }

  /* Control the order of elements */
  .image-viewer {
    order: 0;
  }

  .thumbnail-slider-container {
    order: 1;
    margin-bottom: 30px;
  }

  .section-nav {
    order: 3;
  }

  .about-deal {
    order: 4;
  }

  .fine-print {
    order: 5;
  }

  .customer-reviews {
    order: 6;
  }
}

@media (max-width: 768px) {
  .step-preview {
    padding: 10px;
  }

  .deal-title {
    font-size: 22px;
  }

  .image-viewer {
    height: 300px;
  }

  .section-nav {
    flex-wrap: wrap;
  }

  .modal {
    width: 95%;
    margin: 10px;
  }
}

@media (max-width: 480px) {
  .deal-title {
    font-size: 20px;
  }

  .image-viewer {
    height: 250px;
  }

  .section-nav {
    gap: 10px;
  }

  .section-nav a {
    font-size: 14px;
  }

  .option-pricing {
    flex-direction: column;
    align-items: flex-start;
  }

  .discount {
    margin-top: 5px;
  }

  .quantity-control {
    flex: 1;
    margin-right: 10px;
  }

  .add-to-cart {
    flex: 2;
  }
}
</style>