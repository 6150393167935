<template>
  <div class="coupon-card" @click="navigateToCouponPage">
    <div class="coupon-image">
      <img :src="couponImageSrc" alt="Coupon Image" />
    </div>
    <div class="coupon-content">
      <div class="coupon-header">
        <h3 class="deal-name">{{ truncatedDealTitle }}</h3>
        <div class="star-rating">
          <span v-for="index in 5" :key="index" class="star-wrapper">
            <i class="star" :class="getStarClass(index)">★</i>
          </span>
        </div>
      </div>
      <div class="get-deal-button-container" v-if="!isLoggedIn">
        <div 
          class="get-deal-button" 
          @click.stop="handleDealClick"
          @mouseover="handleDealHover"
          @mouseleave="handleDealMouseLeave"
        >
          <span>GET THIS DEAL</span>
        </div>
        <div class="dashed-box">XXXXXX</div>
      </div>
      <div v-else class="coupon-code-container">
        <div class="coupon-code-box">{{ couponCode }}</div>
        <div class="copy-button" @click.stop="copyCode">{{ copyButtonText }}</div>
      </div>
      <div class="coupon-actions" @click.stop>
        <button 
          v-if="!isInShoppingBag"
          class="action-button" 
          @click="saveCoupon" 
          title="Save Coupon"
          :disabled="!isLoggedIn"
        >
          <img src="https://img.icons8.com/ios-glyphs/30/1A3819/shopping-bag.png" alt="Save Coupon" class="icon" />
        </button>
        <button 
          v-else
          class="action-button remove" 
          @click="removeCoupon" 
          title="Remove Coupon"
          :disabled="!isLoggedIn"
        >
          <img src="https://img.icons8.com/ios-glyphs/30/ffffff/delete-sign.png" alt="Remove Coupon" class="icon" />
        </button>
        <button 
          class="action-button" 
          @click="printCoupon" 
          title="Print Coupon"
          :disabled="!isLoggedIn"
        >
          <img src="https://img.icons8.com/ios-glyphs/30/1A3819/print.png" alt="Print Coupon" class="icon" />
        </button>
      </div>
      <div v-if="message" class="message" :class="{ fade: fading }">{{ message }}</div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import defaultImage from '@/assets/default-image.png';

export default {
  name: 'CouponCard',
  props: {
    couponId: {
      type: String,
      required: true
    },
    dealTitle: {
      type: String,
      required: true
    },
    rating: {
      type: Number,
      default: 0 // Set default value to 0
    },
    couponImage: {
      type: String,
      default: ''
    },
    couponCode: {
      type: String,
      required: true
    },
    category: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      message: '',
      fading: false,
      isLoggedIn: false,
      showFakeCode: false,
      copyButtonText: 'Copy',
      userId: null,
      isInShoppingBag: false, // Add this flag
      token: localStorage.getItem('token'),
    };
  },
  computed: {
    truncatedDealTitle() {
      return this.dealTitle.length > 60 ? this.dealTitle.substring(0, 60) + '...' : this.dealTitle;
    },
    couponImageSrc() {
      return this.couponImage || defaultImage;
    }
  },
  mounted() {
    this.isLoggedIn = !!localStorage.getItem('user');
    this.checkIfInShoppingBag(); // Call this method to check if the coupon is in the shopping bag
    window.addEventListener('update-shopping-bag', this.checkIfInShoppingBag);
  },
  beforeUnmount() {
    window.removeEventListener('update-shopping-bag', this.checkIfInShoppingBag);
  },
  methods: {
   async navigateToCouponPage() {
      await this.$router.push(`/coupon/${this.couponId}`).then(() => {
        location.reload();
                window.scrollTo(0, 0);
      });
      this.logInteraction('click');
    },
    saveCoupon(event) {
      event.stopPropagation();
      const userId = JSON.parse(localStorage.getItem('user'));
      if (this.isLoggedIn) {
        let shoppingBagItems = JSON.parse(localStorage.getItem('shoppingBagItems')) || [];
        const couponExists = shoppingBagItems.some(item => item._id === this.couponId);

        if (couponExists) {
          this.showMessage('Coupon already saved');
        } else {
          axios.post(`${process.env.VUE_APP_API_URL}/api/shoppingCart/${userId.id}`, {
            couponId: this.couponId,
            name: this.dealTitle,
            couponCode: this.couponCode,
            couponImage: this.couponImage,
            category: this.category,
          }, {
            headers: {
              Authorization: `Bearer ${this.token}`
            }
          
          })
          .then(() => {
            let shoppingBagItems = JSON.parse(localStorage.getItem('shoppingBagItems')) || [];
            const item = { couponId: this.couponId, name: this.dealTitle, couponCode: this.couponCode, couponImage: this.couponImage, category: this.category };
            shoppingBagItems.push(item);
            localStorage.setItem('shoppingBagItems', JSON.stringify(shoppingBagItems));
            const event = new CustomEvent('update-shopping-bag', { detail: shoppingBagItems });
            window.dispatchEvent(event);
            this.showMessage('Coupon saved');
            this.isInShoppingBag = true; // Update the flag
          })
          .catch((error) => {
            console.error('Error saving to shopping cart:', error);
          });
        }
      }
    },
    async logInteraction(interactionType) {
      const userId = JSON.parse(localStorage.getItem('user'));
      if (!userId) {
        // Skip logging interactions if user is not logged in
        return;
      }
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/logInteraction`, {
          userId: userId.id, // Include the userId
          couponId: this.couponId,
          interactionType: interactionType
        });
      } catch (error) {
        console.error('Error logging interaction:', error);
      }
    },
    showMessage(text) {
      this.message = text;
      this.fading = false;
      setTimeout(() => {
        this.fading = true;
        setTimeout(() => {
          this.message = '';
          this.fading = false;
        }, 1000); // Duration of the fade-out effect
      }, 2000); // Duration the message stays visible before fading out
    },
    handleDealClick(event) {
      event.stopPropagation();
      if (!this.isLoggedIn) {
        this.$router.push('/login');
        window.scrollTo(0, 0);
      }
    },
    handleDealHover() {
      if (!this.isLoggedIn) {
        this.showFakeCode = true;
      }
    },
    handleDealMouseLeave() {
      this.showFakeCode = false;
    },
    copyCode(event) {
      event.stopPropagation();

      const textToCopy = this.couponCode;

      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // Avoid scrolling to bottom
      textArea.style.position = "fixed";
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.width = "2em";
      textArea.style.height = "2em";
      textArea.style.padding = "0";
      textArea.style.border = "none";
      textArea.style.outline = "none";
      textArea.style.boxShadow = "none";
      textArea.style.background = "transparent";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.copyButtonText = 'Copied';
          setTimeout(() => {
            this.copyButtonText = 'Copy';
          }, 2000); // Reset the text after 2 seconds
        } else {
          console.error('Fallback: Oops, unable to copy');
        }
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },
    checkIfInShoppingBag() {
      const shoppingBagItems = JSON.parse(localStorage.getItem('shoppingBagItems')) || [];
      this.isInShoppingBag = shoppingBagItems.some(item => item.couponId === this.couponId);
    },
    removeCoupon(e) {
      e.stopPropagation();
      const user = JSON.parse(localStorage.getItem('user')).id;
      const name = this.dealTitle; // Ensure the name is included in the request
      axios.put(`${process.env.VUE_APP_API_URL}/api/shoppingCart/remove-from-shopping-bag/${user}`, { couponId: this.couponId, name }, {
        headers: {
          Authorization: `Bearer ${this.token}`
        } 
      })
        .then(() => {
          let shoppingBagItems = JSON.parse(localStorage.getItem('shoppingBagItems')) || [];
          shoppingBagItems = shoppingBagItems.filter(item => item.couponId !== this.couponId || item.name !== name);
          localStorage.setItem('shoppingBagItems', JSON.stringify(shoppingBagItems));
          const updateEvent = new CustomEvent('update-shopping-bag', { detail: shoppingBagItems });
          window.dispatchEvent(updateEvent);
          this.showMessage('Coupon removed');
          this.isInShoppingBag = false; // Update the flag
        })
        .catch((error) => {
          console.error('Error removing from shopping bag:', error);
        });
    },
    printCoupon() {
      const imageUrl = this.couponImage || '@/assets/default-image.png';
      const discountType = this.dealDetails.discounttype;
      let discountText = '';

      if (discountType === 'percentageAmount') {
        discountText = `${this.dealDetails.discount}% off if you spend $${ this.dealDetails.spendamount ? this.dealDetails.spendamount[0] : 0 }`;
      } else if (discountType === 'amountOff') {
        discountText = `Discount: $${this.dealDetails.discount} Off`;
      } else if (discountType === 'buyOneGetOnePercentage') {
        discountText = `${this.dealDetails.discount}% Off`;
      } else if (discountType === 'buyOneGetOneAmount') {
        discountText = `Discount: $${this.dealDetails.discount} Off`;
      } else if (discountType === 'buyOneGetOneFree') {
        discountText = 'Buy 1 Get 1 Free';
      }

      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Coupon</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                padding: 20px;
                background-color: #fff;
                color: #000;
              }
              .printable-coupon {
                border: 1px solid #ccc;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                max-width: 800px;
                margin: 0 auto;
              }
              .coupon-details {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
              }
              .coupon-details img {
                max-width: 350px;
                max-height: 350px;
                border-radius: 10px;
                margin-right: 20px;
              }
              .coupon-details-text {
                flex: 1;
              }
              .coupon-details-text h1 {
                margin-bottom: 10px;
              }
              .coupon-details-text p {
                font-size: 1.2em;
                margin: 5px 0;
              }
              .about-deal, .fine-print {
                margin-top: 20px;
              }
              .about-deal h2, .fine-print h2 {
                border-bottom: 1px solid #ccc;
                padding-bottom: 10px;
              }
            </style>
          </head>
          <body>
            <div class="printable-coupon">
              <div class="coupon-details">
                <img src="${imageUrl}" alt="Coupon Image">
                <div class="coupon-details-text">
                  <h1>${this.dealTitle}</h1>
                  <p><strong>Coupon Name:</strong> ${this.dealTitle}</p>
                  <p><strong>Coupon Code:</strong> ${this.couponCode}</p>
                  <p><strong>Discount:</strong> ${discountText}</p>
                </div>
              </div>
            </div>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    },
    getStarClass(index) {
      if (this.rating === 0) {
        return 'blank';
      } else if (index <= Math.floor(this.rating)) {
        return 'filled';
      } else if (index === Math.ceil(this.rating) && this.rating % 1 !== 0) {
        return 'half-filled';
      } else {
        return 'blank';
      }
    }
  }
}
</script>


<style scoped>
.coupon-card {
  border-radius: 8px;
  max-width: 300px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex: 1 0 calc(25% - 10px);
  margin: 5px;
  font-family: 'Arial', sans-serif;
  margin-bottom: 50px;
  position: relative; /* Added for absolute positioning within the card */
  cursor: pointer; /* Added to show that the card is clickable */
}

.coupon-image{
  background-color: white;
}

.coupon-image img {
  width: 100%;
  display: block;
  height: 100%;
  max-height: 150px;
  object-fit: contain;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color:white ;
}

.coupon-content {
  background-color: #fff;
  padding: 1rem;
  text-align: left;
}

.coupon-header {
  display: flex;
  flex-direction: column;
}

.deal-name {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.star-rating {
  display: flex;
  justify-content: flex-start;
  margin: 0.5rem 0;
}

.star-wrapper {
  position: relative;
}

.star {
  color: #ddd;
  font-style: normal;
  font-size: 1.5rem;
}

.star.filled {
  color: #ffc107;
}

.star.half-filled {
  position: relative;
  display: inline-block;
}

.star.half-filled::before {
  content: '★';
  position: absolute;
  left: 0;
  width:60%;
  overflow: hidden;
  color: #ffc107;
}

.deal-details {
  margin-top: 10px;
}

.option-details {
  margin-bottom: 5px;
}

.option-detail {
  font-size: 1rem;
}

.get-deal-button-container {
  position: relative;
  margin: 20px 0;
  overflow: hidden; /* Ensure the dashed box doesn't overflow the container */
}

.get-deal-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4C6B30;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  border: none;
  outline: none;
  transition: transform 0.3s ease;
  z-index: 2;
}

.get-deal-button::before,
.get-deal-button::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
}

.get-deal-button::before {
  border-width: 0 20px 20px 0;
  border-color: #4C6B30 transparent transparent transparent;
}

.get-deal-button::after {
  border-width: 10px 10px 0 0;
  border-color: #6E9F49 transparent transparent transparent;
}

.get-deal-button:hover {
  transform: translateX(-5px);
}

.dashed-box {
  position: absolute;
  top: 50%;
  right: 130px; /* Adjust to position behind the button */
  transform: translateY(-50%) translateX(50%); /* Ensure it moves behind the button */
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  border: 1px dashed #ccc;
  padding: 9px 10px;
  background-color: #fff;
  z-index: 1; /* Ensure it stays behind the button */
  width: 70px; /* Adjust width to show part of the letter */
  overflow: hidden;
  text-overflow: ellipsis;
}

.coupon-code-container {
  margin: 10px 0; /* Adjust as needed */
  text-align: left;
  display: flex;
  align-items: center;
}

.coupon-code-box {
  display: inline-block;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  border: 1px dashed #ccc;
  padding: 5px 10px;
  background-color: #fff;
  z-index: 1; /* Ensure it stays behind the button */
  width: 130px; /* Adjust width as needed */
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy-button {
  display: inline-block;
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #4C6B30;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  border: none;
  outline: none;
}

.coupon-actions {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 1rem;
}

.action-button {
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  background-color: #eff1f3;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.action-button.remove {
  background-color: #ff4d4d;
  color: #fff;
}

.action-button.remove:hover {
  background-color: #ff0000;
}

.action-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.icon {
  width: 25px;
  height: 25px;
}

/* Tooltip Styles */
.action-button[title]:hover::after {
  content: attr(title);
  position: absolute;
  bottom: calc(100% + 10px);
  left: 95%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 0.5rem;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.8rem;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s;
}

.message {
  position: absolute;
  top: 10px; /* Adjust to position at the top of the card */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 0.8rem;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.message.fade {
  opacity: 0;
}

@media(max-width: 1024px){
  .dashed-box {
  right: 175px; /* Adjust to position behind the button */
  transform: translateY(-50%) translateX(50%); /* Ensure it moves behind the button */
}
}

@media (max-width: 768px) {
  .coupon-card {
    max-width: none;
    width: 90%;
    min-height: 400px;
    max-height: 600px;
  }

  .coupon-image img {
    height: auto;
  }

  .coupon-content {
    padding: 1rem;
  }

  .deal-details,
  .coupon-actions,
  .get-deal-button-container,
  .coupon-code-container {
    flex: 1;
  }
}

</style>
