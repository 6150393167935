<template>
    <div class="notification-signup">
      <!-- Notification Button -->
      <button 
        @click="showModal = true"
        class="alert-button"
      >
      <img src="@/assets/notification.svg" alt="Alert" class="bell-icon filtered-icon"/>
        <span>Become a VIP!</span>
      </button>
  
      <!-- Modal Overlay -->
      <div v-if="showModal" class="modal-overlay" @click.self="showModal = false">
        <div class="modal-content">
          <!-- Close button -->
          <button 
            @click="showModal = false"
            class="close-button"
          >
            ×
          </button>
  
          <div v-if="showSuccess" class="success-alert">
            Successfully signed up for deal alerts!
          </div>
  
          <template v-else>
            <h2 class="modal-title">Never Miss a Deal!</h2>
            <p class="modal-subtitle">Get instant alerts for the best local deals and savings.</p>

            <div v-if="errorMessage" class="error-alert">
                {{ errorMessage }}
            </div>
            
            <form @submit.prevent="handleSubmit" class="signup-form">
              <div class="form-group">
                <label>Name</label>
                <input
                  type="text"
                  v-model="formData.name"
                  :disabled="isLoggedIn"
                  required
                />
              </div>
              <div class="form-group">
                <label>Email</label>
                <input
                  type="email"
                  v-model="formData.email"
                  :disabled="isLoggedIn"
                  required
                />
              </div>
              <div class="form-group">
                <label>Phone Number</label>
                <input
                  type="tel"
                  v-model="formData.phone"
                  :disabled="isLoggedIn"
                  required
                />
              </div>
              <button
                type="submit"
                :disabled="isSubmitting"
                class="submit-button"
              >
                {{ isSubmitting ? 'Signing up...' : 'Sign Up for Alerts' }}
              </button>
            </form>
          </template>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, reactive, onMounted } from 'vue';
  import axios from 'axios';
  
  export default {
    name: 'NotificationSignup',
    setup() {
      const showModal = ref(false);
      const isSubmitting = ref(false);
      const showSuccess = ref(false);
      const isLoggedIn = ref(false);
      const errorMessage = ref('');
      
      const formData = reactive({
        name: '',
        email: '',
        phone: ''
      });
  
      const checkLoginStatus = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
          isLoggedIn.value = true;
          formData.name = user.fullName || '';
          formData.email = user.email || '';
          formData.phone = user.phone || '';
        }
      };
  
      const handleSubmit = async () => {
        isSubmitting.value = true;
        errorMessage.value = '';
        
        try {
          // Add your API endpoint here
          await axios.post(`${process.env.VUE_APP_API_URL}/api/email-list`, formData, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
          
          showSuccess.value = true;
          setTimeout(() => {
            showModal.value = false;
            showSuccess.value = false;
          }, 2000);
        } catch (error) {
          console.error('Error saving notification preferences:', error);
          if(error.response && error.response.status === 409){
            errorMessage.value = 'This email is already subscribed to our alerts.';
          }else{
            errorMessage.value = 'An error occurred. Please try again later.';
          }
        } finally {
          isSubmitting.value = false;
        }
      };
  
      onMounted(() => {
        checkLoginStatus();
      });
  
      return {
        showModal,
        isSubmitting,
        showSuccess,
        isLoggedIn,
        formData,
        handleSubmit,
        errorMessage,
      };
    }
  };
  </script>
  
  <style scoped>
  .notification-signup {
    position: relative;
    margin: 0 20px;
  }
  
  .alert-button {
    display: flex;
    align-items: center;
    background-color: #FFD700;
    color: #1A3819;
    padding: 15px 25px;
    border-radius: 10px;
    font-size: 15px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .alert-button:hover {
    background-color: #FFC800;
  }
  
  .bell-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    padding: 20px;
  }
  
  .modal-content {
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    width: 100%;
    max-width: 400px;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
  }
  
  .modal-title {
    color: #1A3819;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .modal-subtitle {
    color: #666;
    margin-bottom: 24px;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .form-group label {
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
  
  .form-group input {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .form-group input:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  
  .submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover:not(:disabled) {
    background-color: #45a049;
  }
  
  .submit-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .success-alert {
    background-color: #d4edda;
    color: #155724;
    padding: 16px;
    border-radius: 4px;
    text-align: center;
    font-weight: 500;
  }

  .filtered-icon {
    /* This filter will make the icon green (#1A3819) */
    filter: brightness(0) saturate(100%) invert(19%) sepia(23%) saturate(1122%) hue-rotate(93deg) brightness(94%) contrast(91%);
    }

    .error-alert {
        background-color: #f8d7da;
        color: #721c24;
        padding: 16px;
        border-radius: 4px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 16px;
    }    
  
  @media (max-width: 1250px) {
    .notification-signup {
      order: 3;
      width: 45%;
      margin: 5px 0;
    }
  
    .alert-button {
      width: 100%;
      justify-content: center;
    }
  }
  
  @media (max-width: 480px) {

    .notification-signup{
        width: 100%;    
    }

    .modal-content {
      padding: 16px;
    }
  
    .modal-title {
      font-size: 20px;
    }
  }
  </style>