<template>
  <div class="footer">
    <div class="social-section">
      <p>Get connected with us on social networks:</p>
      <div class="social-media-icons">
        <a href="#"><i class="fab fa-facebook"></i></a>
        <a href="#"><i class="fab fa-twitter"></i></a>
        <a href="#"><i class="fab fa-google"></i></a>
        <a href="#"><i class="fab fa-instagram"></i></a>
        <a href="#"><i class="fab fa-linkedin"></i></a>
      </div>
    </div>
    <div class="footer-content">
      <div class="footer-column">
        <img class="footer-image" src="@/assets/logo-2.png" alt="Company Logo">
      </div>
      <div class="footer-column">
        <h5>Quick Links</h5>
        <ul>
          <li><a href="/gift-cards">Gift Cards</a></li>
          <li><a href="/coupons">Coupons</a></li>
          <li><a href="/contact">Contact Us</a></li>
          <li><a href="/support">Support</a></li>
        </ul>
      </div>
      <div class="footer-column">
        <h5>Businesses</h5>
        <ul>
          <li><a href="/business-register">Sell with Us</a></li>
        </ul>
      </div>
      <div class="footer-column">
        <h5>Policies</h5>
        <ul>
          <li><a href="/refund-policy">Refund Policy</a></li>
          <li><a href="/terms-of-service">Terms of Service</a></li>
          <li><a href="/privacy-policy">Privacy Policy</a></li>
        </ul>
      </div>
      <div class="footer-column">
        <h5>Sign up for the latest deals</h5>
          <NotificationSignup />
          </div>
        </div>
    <div class="footer-copyright">
      <p>© 2024 Copyright: Trio Tech Ohio LLC</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import NotificationSignup from './NotificationSignup.vue';
export default {
  data() {
    return {
      email: '',
      subscriptionMessage: '',
      subscriptionError: false,
    };
  },
  components: {
    NotificationSignup,
  }, 
  methods: {
    async subscribe() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/email-list`, { email: this.email });
        this.subscriptionMessage = response.data.message || 'Thank you for subscribing!';
        this.subscriptionError = false;
        this.email = ''; // Clear the input
      } catch (error) {
        console.error('Subscription error:', error);
        this.subscriptionMessage = error.response?.data?.error || 'An error occurred. Please try again.';
        this.subscriptionError = true;
      }
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: #f2f2f2;
  padding: 40px 0;
  color: #333;
}

.social-section {
  background-color: #A3BF8F;
  padding: 20px 0;
  text-align: center;
  color: white;
}

.social-section p {
  margin: 0;
  font-size: 1.2em;
}

.social-media-icons {
  margin-top: 10px;
}

.social-media-icons a {
  color: white;
  margin: 0 10px;
  font-size: 20px;
  text-decoration: none;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  padding: 40px 0;
  background-color: #e9ecef;
  flex-wrap: wrap;
}

.footer-column {
  flex: 1;
  max-width: 250px;
  margin: 10px 0;
}

.footer-column h5 {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #333;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
  color: #666;
}

.footer-column ul li a {
  color: #666;
  text-decoration: none;
}

.footer-column ul li a:hover {
  text-decoration: underline;
}

.footer-column ul li i {
  margin-right: 10px;
}

.footer-column ul li:hover a {
  color: #333;
}

.footer-column form {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.footer-column input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  font-size: 16px;
}

.footer-column button {
  padding: 10px;
  background-color: #4C6B30;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
}

.footer-column button i {
  font-size: 16px;
}

.footer-column p {
  font-size: 0.875em;
  color: #666;
  margin: 0;
}

.footer-image {
  width: 90%;
  height: auto;
  max-width: 300px; /* Restrict max-width to avoid too large images */
}

.subscription-message{
  color:#4C6B30;
}

@media (max-width: 992px) {
  .footer-column {
    flex: 1 1 45%; /* Adjust columns to take half width on medium screens */
    max-width: none;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }
  .footer-column {
    flex: 1 1 100%; /* Make each column take full width on smaller screens */
    max-width: none;
    text-align: center;
  }
  .social-media-icons a {
    margin: 0 5px;
    font-size: 18px;
  }
}
</style>
