<!-- GoogleMap.vue -->
<template>
    <div class="google-map">
      <iframe
        width="100%"
        height="300"
        frameborder="0"
        style="border:0"
        :src="mapUrl"
        allowfullscreen
      ></iframe>
    </div>
  </template>
  
  <script>
  export default {
    name: 'GoogleMap',
    props: {
        address: {
            type: String,
            required: true
        }
    },
    computed: {
      mapUrl() {
        const encodedAddress = encodeURIComponent(this.address);
        return `https://www.google.com/maps/embed/v1/place?key=AIzaSyCE60afA6sO7P_2Dsy9j6o9ymsb8t6Z57s&q=${encodedAddress}`;
      }
    }
  }
  </script>
  
  <style scoped>
  .google-map {
    margin-bottom: 20px;
  }
  </style>