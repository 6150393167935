import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/Frontend/HomePage.vue';
import DashboardPage from '../views/Dashboard/DashboardPage.vue';
import LoginPage from '../views/Frontend/LoginPage.vue';
import CouponsPage from '../views/Frontend/CouponsPage.vue';
import GiftCardsPage from '../views/Frontend/GiftCardsPage.vue';
import SavedDealsPage from '../views/Frontend/SavedDealsPage.vue';
import DashboardCoupon from '../views/Dashboard/DashboardCreateCoupon.vue';
import DashboardGiftCard from '../views/Dashboard/DashboardCreateGiftCard.vue';
import DashboardViewCoupon from '../views/Dashboard/DashboardViewCoupons.vue';
import DashboardViewGiftCard from '../views/Dashboard/DashboardViewGiftCards.vue';
import DashboardEditCoupon from '../views/Dashboard/DashboardEditCoupon.vue';
import RegisterPage from '../views/Frontend/RegisterPage.vue';
import BusinessRegisterPage from '../views/Frontend/BusinessRegisterPage.vue';
import CartPage from '../views/Frontend/CartPage.vue';
import CheckoutPage from '../views/Frontend/CheckoutPage.vue'; 
import AccountPage from '../views/Frontend/AccountPage.vue';
import OrdersPage from '../views/Frontend/OrdersPage.vue';
import PasswordChange from '../views/Frontend/PasswordChange.vue';
import PersonalData from '@/views/Frontend/PersonalData.vue';
import ShoppingBag from '@/views/Frontend/ShoppingBag.vue';
import ViewCoupons from '@/views/Frontend/ViewAllCouponsPage.vue';
import ViewGiftCards from '@/views/Frontend/ViewAllGiftCardsPage.vue';
import IntermediatePage from '@/views/Frontend/GuestCheckoutCheck.vue';
import DashboardNotification from '@/views/Dashboard/DashboardNotification.vue';
import NotificationsPage from '@/views/Frontend/NotificationsPage.vue';
import ReviewsPage from '@/views/Frontend/ReviewsPage.vue';
import DashboardAdministration from '@/views/Dashboard/DashboardAdministration.vue';
import DashboardUpdateUser from '@/views/Dashboard/DashboardUpdateUser.vue';
import DashboardCreateBusiness from '@/views/Dashboard/DashboardCreateBusiness.vue';
import DashboardUpdateBusiness from '@/views/Dashboard/DashboardUpdateBusiness.vue';
import DashboardAdminSales from '@/views/Dashboard/DashboardAdminSales.vue';
import DashboardAdminPayout from '@/views/Dashboard/DashboardAdminPayout.vue';
import DashboardEditGiftCard from '@/views/Dashboard/DashboardEditGiftCard.vue';
import VerifyEmailAfterRegistration from '@/views/Frontend/VerifyEmailAfterRegistration.vue';
import VerifyEmailSuccess from '@/views/Frontend/VerifyEmailSuccess.vue';
import ForgotPassword from '@/views/Frontend/ForgotPassword.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardPage,
    meta: { requiresAuth: true, requiresRole: true }
  },
  {
    path: '/dashboard/create-coupon',
    name: 'dashboard-coupon',
    component: DashboardCoupon,
    meta: { requiresAuth: true, requiresRole: true }
  },
  {
    path: '/dashboard/create-gift-card',
    name: 'dashboard-gift-card',
    component: DashboardGiftCard,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/dashboard/view-coupons',
    name: 'dashboard-view-coupons',
    component: DashboardViewCoupon,
    meta: { requiresAuth: true, requiresRole: true }
  },
  {
    path: '/dashboard/view-gift-cards',
    name: 'dashboard-view-gift-cards',
    component: DashboardViewGiftCard,
    meta: { requiresAuth: true, requiresRole: true }
  },
  {
    path: '/dashboard/edit-coupon/:id',
    name: 'EditCoupon',
    component: DashboardEditCoupon,
    meta: { requiresAuth: true, requiresRole: true }
  },
  {
    path: '/dashboard/edit-gift-card',
    name: 'EditGiftCard',
    component: DashboardEditGiftCard,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/dashboard/remove-gift-card-for-business',
    name: "remove-gift-card-for-business",
    component: () => import('@/views/Dashboard/DashboardDeleteGiftCardForBusiness.vue'),
    meta: {requiresAuth: true, requiresAdmin: true}
  },
  {
    path: '/dashboard/remove-coupon-for-business',
    name: 'dashboard-remove-coupon-for-business',
    component: () => import('@/views/Dashboard/DashboardDeleteCouponForBussiness.vue'),
    meta: {requiresAuth: true, requiresAdmin: true}
  },
  {
    path: '/dashboard/create-coupon-for-business',
    name: 'dashboard-coupon-for-business',
    component: () => import('@/views/Dashboard/DashboardCreateCouponForBussiness.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/dashboard/view-sponsored-items',
    name: 'view-sponsored-items',
    component: () => import('@/views/Dashboard/DashboardAdvertising.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/dashboard/create-sponsored-item',
    name: 'create-sponsored-item',
    component: () => import('@/views/Dashboard/DashboardCreateAdvertisment.vue'),
    meta: { requiresAuth: true, requiresAdmin: true } 
  },
  {
    path: '/dashboard/create-slider-ad',
    name: 'create-slider-ad',
    component: () => import('@/views/Dashboard/DashboardSliderSponsor.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/dashboard/view-slider-ads',
    name: 'view-slider-ads',
    component: () => import('@/views/Dashboard/DashboardViewSliderAd.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/dashboard/create-trending',
    name: 'create-trending',
    component: () => import('@/views/Dashboard/DashboardUpdateTrending.vue'),
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/dashboard/administration',
    name: 'administration',
    component: DashboardAdministration,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      {
        path: 'update-user',
        component: DashboardUpdateUser,
      },
      {
        path: 'create-business',
        component: DashboardCreateBusiness,
      },
      {
        path: 'update-business',
        component: DashboardUpdateBusiness,
      },
      {
        path: 'sales',
        component: DashboardAdminSales,
      },
      {
        path: 'payouts',
        component: DashboardAdminPayout,
      },
      {
        path: 'email-list',
        component: () => import('@/views/Dashboard/DashboardEmailList.vue')
      },
      {
        path: 'approve-reviews',
        component: () => import('@/views/Dashboard/DashboardViewReviews.vue')
      },
      {
        path: 'view-users',
        component: () => import('@/views/Dashboard/DashboardVieweUsers.vue')
      },
    ]
  },
  {
    path: '/dashboard/notification',
    name: 'notification',
    component: DashboardNotification,
    meta: { requiresAuth: true, requiresRole: true }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/login-to-checkout',
    name: 'guest-checkout',
    component: IntermediatePage
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/Frontend/ResetPassword.vue')
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/Frontend/SupportPage.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/Frontend/ContactPage.vue')
  },
  {
    path: '/order-confirmation/:userId/:orderId',
    name: 'OrderConfirmation',
    props: true,
    component: () => import('@/views/Frontend/OrderConfirmation.vue')
  },
  {
    path: '/account/:id',
    name: 'account',
    component: AccountPage,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'orders',
        component: OrdersPage,
      },
      {
        path: 'password-change',
        component: PasswordChange,
      },
      {
        path: 'personal-data',
        component: PersonalData,
      },
      {
        path: 'shopping-bag',
        component: ShoppingBag,
      },
      {
        path: 'notifications',
        component: NotificationsPage,
      },
      {
        path: 'reviews',
        component: ReviewsPage,
      }
      // Add other sub-routes as needed
    ],
  },
  {
    path: '/register',
    component: RegisterPage,
  },
  {
    path: '/verify-email-success',
    name: 'verify-email-success-standalone',
    component: VerifyEmailSuccess
  },
  {
    path: '/verify-email',
    component: VerifyEmailAfterRegistration
  },
  {
    path: '/coupon/:id',
    name: 'coupons',
    component: CouponsPage
  },
  {
    path: '/coupons',
    name: 'view-coupons',
    component: ViewCoupons
  },
  {
    path: '/gift-cards',
    name: 'view-gift-cards',
    component: ViewGiftCards
  },
  {
    path: '/business-register',
    name: 'business-register',
    component: BusinessRegisterPage
  },
  {
    path: '/gift-card/:id',
    name: 'gift-cards',
    component: GiftCardsPage
  },
  {
    path: '/saved-deals',
    name: 'saved-deals',
    component: SavedDealsPage
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartPage
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutPage
  },
  {
    path: '/dashboard/business-profile',
    name: 'business-profile',
    component: () => import('@/views/Dashboard/DashboardNewBusinessProfile.vue'),
    meta: { requiresAuth: true, requiresRole: true }
  },
  {
    path: '/business/:id',
    name: 'BussinessProfile',
    component: () => import('@/views/Frontend/BusinessProfile.vue'),
  },
  {
    path: '/refund-policy',
    name: "refund-policy",
    component: () => import('@/views/Frontend/RefundPolicy.vue')
  },
  {
    path: '/privacy-policy',
    name: "privacy-policy",
    component: () => import('@/views/Frontend/PrivacyPolicy.vue')
  },
  {
    path: '/terms-of-service',
    name: 'terms-and-conditions',
    component: () => import('@/views/Frontend/TermsOfService.vue')
  },
  {
    path: '/how-selling-works',
    name: "how-selling-works",
    component: () => import('@/views/Frontend/HowSellingWorks.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('@/views/Frontend/NotFound.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresRole = to.matched.some(record => record.meta.requiresRole);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  const user = JSON.parse(localStorage.getItem('user'));
  const isAuthenticated = !!user;
  const hasRole = user && (user.isAdmin || user.isBusiness);
  const isAdmin = user && user.isAdmin;

  if (requiresAuth && !isAuthenticated) {
    next('/login');
  } else if (requiresAdmin && !isAdmin) {
    next('/dashboard'); // Redirect to dashboard if not an admin
  } else if (requiresRole && !hasRole) {
    next('/'); // Redirect to home if not authorized
  }else if(to.matched.length === 0){
    next('/404')
  } else {
    next();
  }
});

export default router;
